import template from "./curve-edit.html";
import kognifaiTemplate from "./curve-edit-kognifai.html";
import ko from "knockout";
import events from "App/events";
import CurvePoint from "ViewModels/curves/curvePoint";
import stringHelper from "Utilities/string-helper";
import _ from "underscore";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, Store);

function ViewModel(params) {
    var self = this;
    self.isKognifai = Store.getters["User/isKognifai"];
    self.existingCurves = params.existingCurves;
    self.curveViewModel = params.curveViewModel;
    self.curveMode = params.curveMode;
    self.variables = Vessels.currentVessel.variables;
    self.massFlowRateVariables = Vessels.currentVessel.massFlowRateVariables;
    self.speedVariables = Vessels.currentVessel.speedVariables;

    self.xAxisLogVariableId = ko.observable();
    self.yAxisLogVariableId = ko.observable();
    self.showDuplicateCurveNameWarning = ko.observable(false);
    self.curveFit = ko.observable("Linear");
    self.regressionOrder = ko.observable();
    self.additionaLogVariableIds = ko.observable();
    self.referenceValue = ko.observable();
    self.exponent = ko.observable();
    self.editCurve = self.curveViewModel.curve();

    self.name = ko.observable("New curve");
    self.regressionOrders = [2, 3, 4];
    self.showConfirmDialog = ko.observable(false);
    self.xAxisSelectError = ko.observable(false);
    self.yAxisSelectError = ko.observable(false);
    self.yAxisSameVariableError = ko.observable(false);
    self.isExponentValid = ko.observable(true);
    self.isReferenceValueValid = ko.observable(true);
    self.hasReferenceValueError = ko.observable(false);
    self.hasExponentError = ko.observable(false);

    self.initialAdditionaLogVariableIds = [];
    self.curvePointViewModels = ko.observableArray([]);

    self.xAxisVariables = _.clone(self.variables());
    self.xAxisVariables.push({ id: -1, name: "Time", unit: { caption: "" } });

    //remove log and exp fits from kognifai but not kyma online to avoid causing errors
    if (self.isKognifai)
        self.curveFits = ["Linear", "Polynomial", "Power"];
    else
        self.curveFits = ["Linear", "Polynomial", "Power", "Exponential", "Logarithmic"];

    self.curveFitInfoText = ko.pureComputed(function () {
        var curveFit = self.curveFit();

        var axisText = "";

        if (curveFit === "Power") {
            axisText = "x and y-values";
        } else if (curveFit === "Exponential") {
            axisText = "y-values";
        } else if (curveFit === "Logarithmic") {
            axisText = "x-values";
        } else {
            return "";
        }

        return "Please note that it is not possible to use " + axisText + " 0 or less when calculating the best fit curve " +
            "due to mathematics. Such data points will not be considered";
    });

    self.isNegativeValuesAllowed = ko.pureComputed(function() {
        var curveFit = self.curveFit();

        return curveFit === "Linear" || curveFit === "Polynomial";
    });

    self.isReferencePointsEnabled = ko.pureComputed(function() {
        var curveMode = self.curveMode();

        if (curveMode.value === "SpotMarket") {
            return false;
        }

        return self.isXAndYAxisSelected();
    });

    self.curveTitle = ko.pureComputed(function() {
        var editCurve = self.editCurve;
        var curveMode = self.curveMode();

        var text = editCurve ? "Edit " : "New ";

        if (curveMode.value === "Custom") {
            text += "custom curve";
        } else if (curveMode.value === "TimeCharter") {
            text += "time charter curve";
        } else if (curveMode.value === "SpotMarket") {
            text += "spot market";
        }

        return text;
    });

    self.isXAndYAxisSelected = ko.pureComputed(function () {
        if (self.xAxisLogVariable() && self.yAxisLogVariable()) {
            return true;
        }

        return false;
    });

    self.isXAxisTimeVariable = ko.pureComputed(function() {
        var xAxisLogVariable = self.xAxisLogVariable();
        if (xAxisLogVariable && xAxisLogVariable.name === "Time") {
            return true;
        }

        return false;
    });

    if (self.editCurve) {
        self.name(self.editCurve.name);
        self.xAxisLogVariableId(self.editCurve.xAxisLogVariableId);
        self.yAxisLogVariableId(self.editCurve.yAxisLogVariableId);
        self.curveFit(self.editCurve.curveFit);
        self.regressionOrder(self.editCurve.regressionOrder);
        self.initialAdditionaLogVariableIds = self.editCurve.additionaLogVariableIds;
        self.referenceValue(self.editCurve.referenceValue);
        self.exponent(self.editCurve.exponent);

        var curvePointViewModels = _.map(self.editCurve.points,
            function(point) {
                return new CurvePoint(point, self.curveFit);
            });
        self.curvePointViewModels(curvePointViewModels);

        if (self.xAxisLogVariableId() === null) {
            self.xAxisLogVariableId(-1);
        }
    }

    self.xAxisLogVariable = ko.pureComputed(function () {
        self.xAxisSelectError(false);
        if (self.xAxisLogVariableId()) {
            var variable = _.find(self.xAxisVariables, function (variable) {
                return variable.id === self.xAxisLogVariableId();
            });
        } else if (self.yAxisLogVariableId() && self.xAxisLogVariableId() === -1) {
            var variable = _.find(self.xAxisVariables,
                function(variable) {
                    return variable.name === "Time";
                });
        }
        return variable;
    });

    self.yAxisLogVariable = ko.pureComputed(function () {
        self.yAxisSelectError(false);
        self.yAxisSameVariableError(false);
        var variable = _.find(self.variables(), function (variable) {
            return variable.id === self.yAxisLogVariableId();
        });

        return variable;
    });

    self.referenceCurveDataPoints = ko.pureComputed(function() {
        var curvePointViewModels = self.curvePointViewModels();
        var curveDataPoints = _.map(curvePointViewModels,
            function(curvePointViewModel) {
                return curvePointViewModel.dataPoint();
            });

        curveDataPoints.sort(function(a, b) { return a[0] - b[0]; });
        return curveDataPoints;
    });

    self.isCurveNameValid = ko.pureComputed(function() {
        return !stringHelper.isNullOrWhiteSpace(self.name());
    });

    self.hasAtLeastTwoCurvePoints = ko.pureComputed(function() {
        var curvePointViewModels = self.curvePointViewModels();
        return curvePointViewModels.length > 1;
    });

    self.isCurvePointsValid = ko.pureComputed(function() {
        var curvePointViewModels = self.curvePointViewModels();

        return _.every(curvePointViewModels, function(curvePointViewModel) {
            return curvePointViewModel.isValid();
        });
    });

    self.isCurveValid = ko.pureComputed(function() {
        var isCurveNameValid = self.isCurveNameValid();
        var hasAtLeastTwoCurvePoints = self.hasAtLeastTwoCurvePoints();
        var isCurvePointsValid = self.isCurvePointsValid();
        var isReferenceValueValid = self.isReferenceValueValid();
        var isExponentValid = self.isExponentValid();
        var curveMode = self.curveMode();
        var referenceValue = self.referenceValue();
        var exponent = self.referenceValue();
        var isXAndYAxisSelected = self.isXAndYAxisSelected();

        var isCurveValid = isCurveNameValid && hasAtLeastTwoCurvePoints && isCurvePointsValid;

        if (curveMode.value === "SpotMarket" ) {
            return isCurveNameValid && isReferenceValueValid && isExponentValid;
        } else if (curveMode.value === "Custom") {
            return isCurveNameValid && isXAndYAxisSelected;
        }

        return isCurveValid;
    });

    self.allowedXAxisVariables = ko.pureComputed(function () {
        var curveMode = self.curveMode();

        if (curveMode.value === "Custom") {
            return self.variables();
        }

        return self.speedVariables();
    });

    self.allowedYAxisVariables = ko.pureComputed(function () {
        var curveMode = self.curveMode();

        if (curveMode.value === "Custom") {
            return self.variables();
        }

        return self.massFlowRateVariables();
    });

    self.referenceValueBinding = self.referenceValue.subscribe(function (value) {
        var isValid = !stringHelper.isNullOrWhiteSpace(value) && !_.isNaN(Number(value));
        self.hasReferenceValueError(false);
        self.isReferenceValueValid(isValid);
    });

    self.exponentBinding = self.exponent.subscribe(function (value) {
        var isValid = !stringHelper.isNullOrWhiteSpace(value) && !_.isNaN(Number(value));
        self.hasExponentError(false);
        self.isExponentValid(isValid);
    });

    self.tooltips = {
        curveDetails: "Enter details for curve Name, X- and Y-axis variables. For curve fit please select the method depending on how detailed you want the curve-interpolation to be between each point.",
        curvePoints: "Define the curve, which is normally given as a table of values from a Charter Party contract, speed test results, engine shop data or similar.",
        additionalData: "It is possible to define a few parameters that will be shown both in the table and as additional information in the later plotted graph.",
        power: "Please note that it is not possible to use x- and y-values 0 or less when calculating the best fit curve due to mathematics. Such data points will not be considered.",
    };
}

ViewModel.prototype.done = function() {
    events.editCurveDone.dispatch();
};

ViewModel.prototype.save = function () {
    var hasError = false;
    var self = this;

    if (this.curveMode().value === "SpotMarket") {
        if (!this.referenceValue()) {
            this.hasReferenceValueError(true);
            hasError = true;
        }

        if (!this.exponent()) {
            this.hasExponentError(true);
            hasError = true;
        }
    }

    var curvesToCheck = self.existingCurves();
    if (self.editCurve) {
        curvesToCheck = self.existingCurves().filter(function (curve) {
            return curve.curve().id !== self.editCurve.id;
        });
    }
    curvesToCheck.forEach(function (curve) {
        if (curve.name().toLowerCase() === self.name().toLowerCase()) {
            self.showDuplicateCurveNameWarning(true);
            hasError = true;
        }
    });

    if (!this.isXAndYAxisSelected() || hasError) {
        if (!this.xAxisLogVariable()) {
            this.xAxisSelectError(true);
        }

        if (!this.yAxisLogVariable()) {
            this.yAxisSelectError(true);
        }
    } else {
        var curve = {};
        curve.name = this.name();
        curve.xAxisLogVariableId = this.xAxisLogVariableId() === -1 ? null : this.xAxisLogVariableId();
        curve.yAxisLogVariableId = this.yAxisLogVariableId();
        curve.curveFit = this.curveFit();
        curve.regressionOrder = this.regressionOrder();
        curve.additionaLogVariableIds = this.additionaLogVariableIds();
        curve.referenceValue = this.referenceValue();
        curve.exponent = this.exponent();
        curve.curveMode = this.curveMode().value;
        curve.points = _.map(this.curvePointViewModels(),
            function (curvePointViewModel) {
                return curvePointViewModel.point();
            });

        if (this.xAxisLogVariableId() === this.yAxisLogVariableId()) {
            this.yAxisSameVariableError(true);
            document.getElementById("selectY").scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
            this.curveViewModel.save(curve)
            .done(function () {
                events.editCurveDone.dispatch(curve);
            });
        }
    }
};

ViewModel.prototype.remove = function () {
    this.showConfirmDialog(true);
};

ViewModel.prototype.removeCurveViewModel = function () {
    var self = this;
    this.curveViewModel.remove().done(function () {
        self.showConfirmDialog(false);
        events.editCurveDone.dispatch();
    });
};

ViewModel.prototype.confirmDeleteDialog = function () {
    this.removeCurveViewModel();
};

ViewModel.prototype.cancelDeleteDialog = function () {
    this.showConfirmDialog(false);
};

ViewModel.prototype.showCurveFitInfoTooltip = function (vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.curveFitInfoText(), isWide: true }, element);

};

ViewModel.prototype.showTooltip = function (type, vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.tooltips[type], isWide: true }, element);
};

ViewModel.prototype.closeTooltip = function () {
    events.closeTooltip.dispatch();
};

ViewModel.prototype.dispose = function () {
    this.referenceValueBinding.dispose();
    this.exponentBinding.dispose();
};

var templateToExport = Store.getters["User/isKognifai"] ? kognifaiTemplate : template;

export default { viewModel: ViewModel, template: templateToExport };
