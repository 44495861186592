import ko from "knockout";
import _ from "underscore";

function CurveVariable(id, data, excludedData, average, name, displayName) {
    var self = this;
    self.id = id;
    self.excludedData = excludedData;
    self.average = ko.observable(average);
    self.data = convertDataToReadableList(data);
    self.name = name;
    self.displayName = displayName;
}

function convertDataToReadableList(data) {
    return _.map(data, function(value, time) {
        return { data: value, time: time };
    });
}

CurveVariable.prototype.getAverageText = function () {
    return this.average.toFixed(2);
};

export default CurveVariable;