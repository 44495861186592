import ko from "knockout";
import variableCalculations from "Utilities/curve-variable-calculations-helper";

function CpModule(timePeriod) {
    var self = this;
    self.timePeriod = timePeriod;
    self.xAxisVariable = self.timePeriod.xAxisVariable;
    self.yAxisVariable = self.timePeriod.yAxisVariable;
    self.selectedCurve = self.timePeriod.selectedCurve;

    self.distanceTravelled = ko.observable(0);

    self.actualFuelConsumption = ko.pureComputed(function () {
        if (!self.xAxisVariable()) {
            return parseFloat("NaN");
        }
        var fuelConsumptionData = variableCalculations.getFuelConsumptionData(self.timePeriod, self.yAxisVariable(), false);
        return fuelConsumptionData.fuelConsumption;
    });

    self.referenceFuelConsumption = ko.pureComputed(function () {
        if (!self.xAxisVariable()) {
            return parseFloat("NaN");
        }
        var fuelConsumptionData = variableCalculations.getFuelConsumptionData(self.timePeriod, self.xAxisVariable(), true);
        self.distanceTravelled(fuelConsumptionData.distance);
        return fuelConsumptionData.fuelConsumption;
    });

    self.differenceBetweenConsumptions = ko.pureComputed(function () {
        var actual = self.actualFuelConsumption();
        var reference = self.referenceFuelConsumption();
        return actual - reference;
    });
}

export default CpModule;