import _ from "underscore";

function getIntersectionFromMultidimensionalArray(multidimensionalArray) {
    var array = [];

    _.each(multidimensionalArray, function (list, index) {
        if (index < multidimensionalArray.length - 1) {
            array = _.intersection(list, multidimensionalArray[index + 1]);
        }
        if (array.length === 0) {
            array = list;
        }
    });

    return array;
}

export default {
    getIntersectionFromMultidimensionalArray: function (multidimensionalArray) {
        return getIntersectionFromMultidimensionalArray(multidimensionalArray);
    },
};