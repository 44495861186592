import template from "./curve-specification.html";
import kognifaiTemplate from "./curve-specification-kognifai.html";
import ko from "knockout";
import moment from "moment";
import _ from "underscore";
import events from "App/events";
import CurveVM from "ViewModels/curves/curve";
import FilterGroup from "ViewModels/curves/filterGroup";
import CurvePlot from "ViewModels/curves/curvePlot";
import dateHelper from "Utilities/date-helper";
import stringHelper from "Utilities/string-helper";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, Store);

function ViewModel(params) {
    var self = this;
    var defaultToDate = moment.utc();
    var defaultFromDate = defaultToDate.clone().subtract(1, "days");

    self.curveModes = params.curveModes;
    self.curveMode = params.curveMode;
    self.curveModule = params.curveModule;

    self.hasCurveBeenUpdated = params.hasCurveBeenUpdated;
    self.curvePlots = Vessels.currentVessel.curvePlots;
    self.curves = Vessels.currentVessel.curves;
    self.variables = Vessels.currentVessel.variables;

    self.overwriteCurvePlotName = ko.observable();
    self.showDuplicateCurvePlotNameWarning = ko.observable(false);
    self.vesselName =  ko.observable(Vessels.currentVessel.name);
    self.selectedCurvePlot = ko.observable();
    self.selectedCurve = ko.observable();
    self.curvePlotViewModel = ko.observable(new CurvePlot());
    self.setPeriodFromDate = ko.observable(dateHelper.getFormatedDateString(defaultFromDate));
    self.setPeriodToDate = ko.observable(dateHelper.getFormatedDateString(defaultToDate));
    self.selectedDataInterval = ko.observable("Hour");
    self.selectedModule = ko.observable("cp");
    self.timeConfigurationType = ko.observable("LastPeriod");
    self.name = ko.observable("New plot");
    self.setPeriodFromTime = ko.observable("12:00");
    self.setPeriodToTime = ko.observable("12:00");
    self.periodStartAt = ko.observable("12:00");
    self.isSaveCurvePlotOpen = ko.observable(false);
    self.showConfirmDialog = ko.observable(false);
    self.lastPeriodDays = ko.observable(1);
    self.periodDurationInHours = ko.observable(24);

    self.exclusionFilterGroupViewModels = ko.observableArray([]);
    var isKognifai = Store.getters["User/isKognifai"];
    self.dataIntervals = isKognifai ? ko.observableArray(["Minute", "TenMinutes", "Hour"]) : ko.observableArray(["Minute", "Quarter", "Hour"]);

    self.curvePlotsInCurveModule = ko.pureComputed(function () {
        var curvePlots = self.curvePlots();
        var curveModule = self.curveModule();
        var curves = self.curves();

        return _.filter(curvePlots, function (curvePlot) {
            var curveInCurvePlot = _.find(curves, function (curve) {
                return curvePlot.curvePlot().curveId === curve.curve().id;
            });

            if (!curveInCurvePlot) return false;

            var curveMode = curveInCurvePlot.curve().curveMode;
            if (curveModule === "CharterParty") {
                return curveMode === "TimeCharter" || curveMode === "SpotMarket";
            } else if (curveModule === "DataAnalysis") {
                return curveMode === "Custom";
            }
        });
    });

    self.isCharterPartyModule = ko.pureComputed(function() {
        var curveModule = self.curveModule();

        return curveModule === "CharterParty";
    });

    self.curvesLabel = ko.pureComputed(function() {
        var curveMode = self.curveMode();

        if (curveMode.value === "SpotMarket") {
            return "Formula";
        }

        return "Curve";
    });

    self.curvesInCurveMode = ko.pureComputed(function () {
        var curves = self.curves();
        var curveMode = self.curveMode();

        return _.filter(curves, function (curve) {
            return curve.curve().curveMode === curveMode.value;
        });
    });

    self.curveTitle = ko.pureComputed(function() {
        var isCharterPartyModule = self.isCharterPartyModule();
        var curveMode = self.curveMode();

        if (isCharterPartyModule) {
            if (curveMode.value === "TimeCharter") {
                return "Time charter curve";
            } else if (curveMode.value === "SpotMarket") {
                return "Spot market";
            }
        }

        return "Data analysis";
    });

    self.curvePlot = ko.pureComputed(function() {
        return self.getCurvePlot();
    });

    self.exclusionFilters = ko.pureComputed(function() {
        var filterGroupViewModels = self.exclusionFilterGroupViewModels();

        var filters = _.map(filterGroupViewModels,
            function(filterGroupViewModel) {
                return filterGroupViewModel.filters();
            });

        return filters;
    });

    self.availableVariables = ko.pureComputed(function() {
        var variables = self.variables();
        var selectedCurve = self.selectedCurve();
        if (!selectedCurve) return [];

        var curve = selectedCurve.curve();

        var variableIds = _.flatten([
            curve.xAxisLogVariableId, curve.yAxisLogVariableId, curve.additionaLogVariableIds,
        ]);
        var availabelVariables = _.filter(variables, function(variable) {
            return _.contains(variableIds, variable.id);
        });
        return availabelVariables;
    });

    self.availableVariablesMap = ko.pureComputed(function() {
        var availableVariables = self.variables();
        var variablesMap = {};
        _.each(availableVariables, function(variable) { variablesMap[variable.id] = variable; });
        return variablesMap;
    });

    self.hasSavedCurvePlot = ko.pureComputed(function() {
        var curvePlotViewModel = self.curvePlotViewModel();
        return curvePlotViewModel.curvePlot();
    });

    self.isCurvePlotNameValid = ko.pureComputed(function() {
        return !stringHelper.isNullOrWhiteSpace(self.name());
    });

    self.isPeriodStartAtValid = ko.pureComputed(function() {
        var periodStartAt = self.periodStartAt();
        return dateHelper.isValidTime(periodStartAt);
    });

    self.isPeriodDurationInHoursValid = ko.pureComputed(function() {
        var periodDurationInHours = self.periodDurationInHours();
        return parseFloat(periodDurationInHours) > 0;
    });

    self.isLastPeriodDaysValid = ko.pureComputed(function() {
        var lastPeriodDays = self.lastPeriodDays();
        return parseInt(lastPeriodDays) > 0;
    });

    self.isExclusionFiltersValid = ko.pureComputed(function() {
        var filterGroupViewModels = self.exclusionFilterGroupViewModels();

        return _.every(filterGroupViewModels, function(filterGroupViewModel) {
            return filterGroupViewModel.isFiltersValid();
        });
    });

    self.isSetPeriodFromTimeValid = ko.pureComputed(function() {
        var fromTime = self.setPeriodFromTime();
        return dateHelper.isValidTime(fromTime);
    });

    self.isSetPeriodToTimeValid = ko.pureComputed(function () {
        var toTime = self.setPeriodToTime();
        return dateHelper.isValidTime(toTime);
    });

    self.hasValidCurve = ko.pureComputed(function () {
        var curveViewModels = self.curves();
        var curveMode = self.curveMode();

        //if no curve is selected
        if (!self.selectedCurve()) return false;

        return _.any(curveViewModels, function (curveViewModel) {
            return curveViewModel.curve().curveMode === curveMode.value;
        });
    });

    self.isCurvePlotValid = ko.pureComputed(function () {
        var isPeriodStartAtValid = self.isPeriodStartAtValid();
        var isPeriodDurationInHoursValid = self.isPeriodDurationInHoursValid();
        var isLastPeriodDaysValid = self.isLastPeriodDaysValid();
        var isExclusionFiltersValid = self.isExclusionFiltersValid();
        var isSetPeriodFromTimeValid = self.isSetPeriodFromTimeValid();
        var isSetPeriodToTimeValid = self.isSetPeriodToTimeValid();
        var hasValidCurve = self.hasValidCurve();

        return isPeriodStartAtValid &&
            isPeriodDurationInHoursValid &&
            isLastPeriodDaysValid &&
            isExclusionFiltersValid &&
            isSetPeriodFromTimeValid &&
            isSetPeriodToTimeValid &&
            hasValidCurve;
    });

    self.curvePlotCreatedBinding = events.curvePlotCreated.add(function (curvePlot) {
        var curvePlotViewModel = new CurvePlot(curvePlot);
        self.curvePlots.push(curvePlotViewModel);
        self.curvePlotViewModel(curvePlotViewModel);
        self.selectedCurvePlot(curvePlotViewModel);
    });

    self.curvePlotDeletedBinding = events.curvePlotDeleted.add(function (curvePlot) {
        self.curvePlots.remove(function (curvePlotViewModel) {
            return curvePlotViewModel.curvePlot().id === curvePlot.id;
        });
    });

    self.newCurveAddedBinding = events.curveCreated.add(function (curve) {
        var curveViewModels = self.curves();
        self.selectedCurve(_.find(curveViewModels, function (curveViewModel) {
            return curveViewModel.curve().id === curve.id;
        }));
    });

    self.selectedCurvePlotBinding = self.selectedCurvePlot.subscribe(function (curvePlotViewModel) {
        events.presetChanged.dispatch();
        if (!curvePlotViewModel) {
            self.curvePlotViewModel(new CurvePlot());
            self.name("New plot");
            self.resetPresets();
            return;
        }

        self.curvePlotViewModel(curvePlotViewModel);
        self.initializeSavedPlot();
    });

    self.curveDeletedBinding = events.curveDeleted.add(function(curve) {
        var curvePlots = self.curvePlots();
        var updatedCurvePlots = _.filter(curvePlots, function(curvePlot) {
            return curvePlot.curvePlot().curveId !== curve.id;
        });

        self.selectedCurvePlot("");
        self.curvePlots(updatedCurvePlots);
    });

    self.yAxisLogVariableId = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve();
        if (!selectedCurve) return null;

        var curve = selectedCurve.curve();
        if (!curve) return null;

        return curve.yAxisLogVariableId;
    });

    self.tooltips = {
        dataAnalysis: "Input your custom selections for your analysis below and store the settings for later easy retrieval.",
        timeSpan: "Select the time to analyse, with the period split into length as specified by the ‘Period duration’.",
        exclusionFilters: "Apply exclusion filtering by exclude time, outliers, bad weather, etc. Use logical expressions to build your custom exclusion filters.",
        curve: "Specify a baseline curve, against which the data will be compared.",
    };
}

ViewModel.prototype.dispose = function() {
    this.curvePlotSelectedBinding.detach();
};

ViewModel.prototype.toggleSaveCurvePlot = function() {
    this.showDuplicateCurvePlotNameWarning(false);
    this.isSaveCurvePlotOpen(!this.isSaveCurvePlotOpen());
};

//Selecting nothing (the select line) in the Load presets box resets all settings
ViewModel.prototype.resetPresets = function() {
    var defaultToDate = moment.utc();
    var defaultFromDate = defaultToDate.clone().subtract(1, "days");
    this.selectedCurve(undefined);
    this.setPeriodFromDate(dateHelper.getFormatedDateString(defaultFromDate));
    this.setPeriodToDate(dateHelper.getFormatedDateString(defaultToDate));
    this.timeConfigurationType("LastPeriod");
    this.setPeriodFromTime("12:00");
    this.setPeriodToTime("12:00");
    this.lastPeriodDays(1);
    this.periodDurationInHours(24);
    this.exclusionFilterGroupViewModels([]);
};

ViewModel.prototype.initializeSavedPlot = function(curvePlotViewModel) {
    var self = this;
    var curvePlot = self.curvePlotViewModel().curvePlot();
    self.name(curvePlot.name);

    var curveViewModels = self.curves();
    var curve = _.find(curveViewModels,
        function(curveViewModel) {
            return curveViewModel.curve().id === curvePlot.curveId;
        });

    self.selectedCurve(curve);

    var curveModule = self.curveModule();
    if (curveModule === "CharterParty") {
        var curveMode = _.find(self.curveModes(),
            function(curveMode) { return curveMode.value === curve.curve().curveMode; });

        self.curveMode(curveMode);
    }

    var fromTime = curvePlot.setPeriodFromDate.split("T")[1];
    var toTime = curvePlot.setPeriodToDate.split("T")[1];

    self.timeConfigurationType(curvePlot.timeConfigurationType);
    self.lastPeriodDays(curvePlot.lastPeriodInSeconds / 24 / 60 / 60);
    self.setPeriodFromDate(dateHelper.getFormatedDateString(curvePlot.setPeriodFromDate));
    self.setPeriodToDate(dateHelper.getFormatedDateString(curvePlot.setPeriodToDate));
    self.setPeriodFromTime(fromTime.substring(0, 5));
    self.setPeriodToTime(toTime.substring(0, 5));

    self.selectedDataInterval(curvePlot.dataInterval);
    self.selectedModule(curvePlot.curvePlotModule);
    self.periodStartAt(curvePlot.periodStartAt.substring(0, 5));
    self.periodDurationInHours(curvePlot.periodDurationInHours);

    var exclusionFilterGroupViewModels = _.map(curvePlot.exclusionFilters,
        function (exclusionFilterGroup) {
            return new FilterGroup(self.availableVariablesMap, self.curveMode, self.yAxisLogVariableId, exclusionFilterGroup);
        });
    self.exclusionFilterGroupViewModels(exclusionFilterGroupViewModels);
};

ViewModel.prototype.getCurvePlot = function() {
    var self = this;
    var curvePlot = self.curvePlotViewModel().curvePlot();
    var id = curvePlot ? curvePlot.id : null;

    var curve = self.selectedCurve();
    var curveMode = self.curveMode();
    var timeConfigurationType = self.timeConfigurationType();
    var setPeriodFromTime = self.setPeriodFromTime();
    var setPeriodToTime = self.setPeriodToTime();
    var setPeriodFromDate = dateHelper.getSubmitDateString(self.setPeriodFromDate(), setPeriodFromTime);
    var setPeriodToDate = dateHelper.getSubmitDateString(self.setPeriodToDate(), setPeriodToTime);
    var periodStartAt = self.periodStartAt();
    var exclusionFilters = self.exclusionFilters();
    var name = self.name();
    var dataInterval = self.selectedDataInterval();

    var curvePlotModule = self.selectedModule();
    var lastPeriodDays = parseInt(self.lastPeriodDays());
    var periodDurationInHours = parseFloat(self.periodDurationInHours());

    var lastPeriodInSeconds = lastPeriodDays * 24 * 60 * 60;

    var plotSpecification = {
        id: id,
        curveMode: curveMode.value,
        curveId: curve.curve().id,
        name: name,
        timeConfigurationType: timeConfigurationType,
        lastPeriodInSeconds: lastPeriodInSeconds,
        setPeriodFromDate: setPeriodFromDate,
        setPeriodToDate: setPeriodToDate,
        periodStartAt: periodStartAt,
        periodDurationInHours: periodDurationInHours,
        exclusionFilters: exclusionFilters,
        dataInterval: dataInterval,
        setPeriodFromTime: setPeriodFromTime,
        setPeriodToTime: setPeriodToTime,
        curvePlotModule: curvePlotModule,
    };
    return plotSpecification;
};

ViewModel.prototype.showEditCurve = function() {
    this.showDuplicateCurvePlotNameWarning(false);
    events.editCurveStart.dispatch(this.selectedCurve());
};

ViewModel.prototype.showAddNewCurve = function() {
    events.editCurveStart.dispatch(new CurveVM(null));
};

ViewModel.prototype.plot = function() {
    var self = this;
    self.showDuplicateCurvePlotNameWarning(false);
    var curvePlot = self.getCurvePlot();
    events.curvePlotSpecified.dispatch(curvePlot);
    //blur the button after click
    if (document.activeElement != document.body)
        document.activeElement.blur();
};

ViewModel.prototype.savePlot = function() {
    var self = this;
    var curvePlot = self.getCurvePlot();
    var curvePlotViewModel = self.curvePlotViewModel();
    return curvePlotViewModel.save(curvePlot).done(function() {
        self.showDuplicateCurvePlotNameWarning(false);
        self.isSaveCurvePlotOpen(false);
    });
};

ViewModel.prototype.saveAsNewPlot = function() {
    var self = this;
    var curvePlotNameAlreadyExists = false;

    self.curvePlots().forEach(function(curvePlot) {
        if (curvePlot.name() === self.getCurvePlot().name) {
            curvePlotNameAlreadyExists = true;
        }
    });

    if (curvePlotNameAlreadyExists) {
        self.overwriteCurvePlotName = self.getCurvePlot().name;
        return self.showDuplicateCurvePlotNameWarning(true);
    }

    self.curvePlotViewModel(new CurvePlot());
    self.savePlot();
};

ViewModel.prototype.updatePlot = function() {
    var self = this;

    var curvePlotNameAlreadyExists;

    self.curvePlots().forEach(function(curvePlot) {
        if (curvePlot.name() === self.getCurvePlot().name && curvePlot.curvePlot().id != self.getCurvePlot().id) {
            curvePlotNameAlreadyExists = true;
        }
    });

    if (curvePlotNameAlreadyExists) {
        self.overwriteCurvePlotName = self.getCurvePlot().name;
        return self.showDuplicateCurvePlotNameWarning(true);
    }

    self.savePlot();
};

ViewModel.prototype.overwritePlot = function() {
    var self = this;
    var curvePlotName = self.overwriteCurvePlotName;
    var curvePlotToOverwrite;

    self.curvePlots().forEach(function(curvePlot) {
        if (curvePlot.name() === curvePlotName) {
            curvePlotToOverwrite = curvePlot;
        }
    });

    self.curvePlotViewModel(curvePlotToOverwrite);
    self.deleteCurvePlotViewModel();

    var updatedCurvePlots = _.filter(self.curvePlots(), function(curvePlot) {
        return curvePlot.curvePlot().name !== curvePlotName;
    });
    self.curvePlots(updatedCurvePlots);

    self.name(curvePlotName);
    self.saveAsNewPlot();
    self.showDuplicateCurvePlotNameWarning(false);
};

ViewModel.prototype.deletePlot = function () {
    this.showConfirmDialog(true);
};

ViewModel.prototype.confirmDeleteDialog = function () {
    this.deleteCurvePlotViewModel();
};

ViewModel.prototype.cancelDeleteDialog = function () {
    this.showConfirmDialog(false);
};

ViewModel.prototype.deleteCurvePlotViewModel = function() {
    var self = this;
    var curvePlotViewModel = self.curvePlotViewModel();
    curvePlotViewModel.remove().done(function () {
        self.showConfirmDialog(false);
        self.curvePlotViewModel(new CurvePlot());
        self.isSaveCurvePlotOpen(false);
    });
};

ViewModel.prototype.showTooltip = function (type, vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.tooltips[type], isWide: true }, element);
};

ViewModel.prototype.closeTooltip = function () {
    events.closeTooltip.dispatch();
};

ViewModel.prototype.dispose = function () {
    this.curvePlotCreatedBinding.detach();
    this.curvePlotDeletedBinding.detach();
    this.newCurveAddedBinding.detach();
    this.curveDeletedBinding.detach();
    this.selectedCurvePlotBinding.dispose();
};

var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default { viewModel: ViewModel, template: templateToExport };
