import _ from "underscore";

function getFuelDataFromValues(currentValue, comparableValue, isReferenceData, timePeriod) {
    var self = this;
    var data = isReferenceData ? timePeriod.getReferenceValue(currentValue.data) : currentValue.data;
    var isTimeSpanLessThanDataInterval = timePeriod.isTimeSpanLessThanDataInterval();

    var currentTime = isTimeSpanLessThanDataInterval ? timePeriod.startDate : currentValue.time;
    var endTime = isTimeSpanLessThanDataInterval ? timePeriod.endDate : comparableValue.time;

    var timeDifferenceInHours = timePeriod.getTimeDifferenceInHours(endTime, currentTime);
    var timeDifferenceInDays = timeDifferenceInHours / 24;

    var fuelConsumption = data * timeDifferenceInDays;

    if (isReferenceData) {
        var distance = currentValue.data * timeDifferenceInHours;
    }

    return { fuelConsumption: fuelConsumption, distance: distance };
}

function getFuelConsumptionData(timePeriod, variable, isReferenceData) {
    var variableData = variable.data;
    var totalTime = 0;
    var fuelConsumption = 0;
    var distance = 0;

    if (variableData.length === 1) {
        var previousValue = variableData[0];
        var comparableValue = { data: null, time: timePeriod.endDate };

        fuelData = getFuelDataFromValues(previousValue, comparableValue, isReferenceData, timePeriod);

        fuelConsumption += fuelData.fuelConsumption;
        distance += fuelData.distance;
    }

    for (var i = 1; i < variableData.length; i++) {
        var previousValue = variableData[i - 1];
        var comparableValue = variableData[i];

        if (!_.isNaN(previousValue.time) && !_.isNaN(comparableValue.time)) {
            var fuelData = getFuelDataFromValues(previousValue, comparableValue, isReferenceData, timePeriod);
            fuelConsumption += fuelData.fuelConsumption;
            distance += fuelData.distance;

            if (i === variableData.length - 1 && i > 0) {
                previousValue = comparableValue;
                comparableValue = { data: null, time: timePeriod.endDate };
                fuelData = getFuelDataFromValues(previousValue, comparableValue, isReferenceData, timePeriod);

                fuelConsumption += fuelData.fuelConsumption;
                distance += fuelData.distance;
            }
        }
    }
    return { fuelConsumption: fuelConsumption, distance: distance };
}

function getWeightedData(currentValue, comparableValue, timePeriod) {
    var isTimeSpanLessThanDataInterval = timePeriod.isTimeSpanLessThanDataInterval();
    var offset = getOffset(timePeriod, currentValue);

    var endTime = isTimeSpanLessThanDataInterval ? timePeriod.endDate : comparableValue.time;

    if (timePeriod.isLastTimePeriod) {
        var currentTime = isTimeSpanLessThanDataInterval ? timePeriod.startDate : (+currentValue.time + offset);
    } else if (offset > 0) {
        var currentTime = isTimeSpanLessThanDataInterval ? timePeriod.startDate : (+currentValue.time + offset);
        endTime = isTimeSpanLessThanDataInterval ? comparableValue.time : comparableValue.time;
    } else if (offset < 0) {
        var currentTime = isTimeSpanLessThanDataInterval ? currentValue.time : currentValue.time;
    } else {
        var currentTime = isTimeSpanLessThanDataInterval ? timePeriod.startDate : currentValue.time;
    }

    var weight = timePeriod.getTimeDifferenceInHours(endTime, currentTime);
    var weightedAverageValue = currentValue.data * weight;

    return { weight: weight, average: weightedAverageValue };
}

function getOffset(timePeriod, data) {
    return (timePeriod.startDate - data.time);
}

function calculateWeightedAverage(timePeriod, variableData) {
    var self = this;
    var average = 0;
    var weightSum = 0;

    if (variableData.length === 1) {
        var previousValue = variableData[0];
        var excludedData = timePeriod.variableDataList()[0].excludedData[0];

        if (timePeriod.variableDataList()[0].excludedData.length !== 0) {
            var comparableValue = excludedData.time < previousValue.time ? { data: null, time: timePeriod.endDate } : { data: null, time: excludedData.time };
        } else {
            var comparableValue = { data: null, time: timePeriod.endDate };
        }

        var weightedData = getWeightedData(previousValue, comparableValue, timePeriod);
        weightSum += weightedData.weight;
        average += weightedData.average;
    }

    for (var i = 1; i < variableData.length; i++) {
        var previousValue = variableData[i - 1];
        var comparableValue = variableData[i];

        if (!_.isNaN(previousValue.time) && !_.isNaN(comparableValue.time)) {
            var weightedData = getWeightedData(previousValue, comparableValue, timePeriod);

            weightSum += weightedData.weight;
            average += weightedData.average;
                    
            if (i === variableData.length - 1 && i > 0) {
                previousValue = comparableValue;
                comparableValue = { data: null, time: timePeriod.endDate };

                weightedData = getWeightedData(previousValue, comparableValue, timePeriod);

                weightSum += weightedData.weight;
                average += weightedData.average;
            }
        }
    }

    var weightedAverage = average / weightSum;
    return {weightedAverage: weightedAverage, includedTimeInHours: weightSum};
}

function getGranularityAsMillisecond(granularity) {
    switch (granularity) {
        case "Minute":
            return 60000;
        case "Quarter":
        case "quarterHour":
            return 900000;
        case "Hour":
            return 3600000;
        default:
            break;
    }
}

export default {
    getWeightedAverageOfVariableData: function(timePeriod, variableData) {
        return calculateWeightedAverage(timePeriod, variableData);
    },
    getFuelConsumptionData: function (timePeriod, variable, isReferenceData) {
        return getFuelConsumptionData(timePeriod, variable, isReferenceData);
    },
    getGranularityAsMillisecond: function(granularity) {
        return getGranularityAsMillisecond(granularity);
    },
    getWeightedData: function(currentValue, comparableValue, timePeriod) {
        return getWeightedData(currentValue, comparableValue, timePeriod);
    },
};