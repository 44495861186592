import ko from "knockout";
import _ from "underscore";
import curvePlotsClient from "Clients/curve-plots-client";

var ViewModel = function(curvePlot) {
    var self = this;
    self.curvePlot = ko.observable(curvePlot);
    self.name = ko.pureComputed(function() {
        var curvePlot = self.curvePlot();
        if (!curvePlot) return "";
        return curvePlot.name;
    });
};

ViewModel.prototype.save = function(newCurvePlot) {
    var self = this;
    var originalCurvePlot = _.clone(self.curvePlot()) || {};
    var curvePlot = _.extend(originalCurvePlot, newCurvePlot);
    var promise;
    if (curvePlot.id) {
        promise = curvePlotsClient.update(curvePlot);
    } else {
        promise = curvePlotsClient.create(curvePlot);
    }
    promise.done(function(data) {
        self.curvePlot(data);
    });
    return promise;
};

ViewModel.prototype.remove = function() {
    return curvePlotsClient.remove(this.curvePlot());
};

export default ViewModel;