import ko from "knockout";

//We have to import and register these components after user has been loaded
//because they have to know what template to use based on if user is kognifai
//or not.
import curves from "Components/curves/curves/curves";
import curveEdit from "Components/curves/curve-edit/curve-edit";
import curveDataTable from "Components/curves/curve-data-table/curve-data-table";
import curveDetails from "Components/curves/curve-details/curve-details";
import curvePlotFilters from "Components/curves/curve-plot-filters/curve-plot-filters";
import curvePoints from "Components/curves/curve-points/curve-points";
import curveSpecification from "Components/curves/curve-specification/curve-specification";
import variableSelectMulti from "Components/variable-select-multi/variable-select-multi";

ko.components.register("curves", curves);
ko.components.register("curve-edit", curveEdit);
ko.components.register("curve-data-table", curveDataTable);
ko.components.register("curve-details", curveDetails);
ko.components.register("curve-plot-filters", curvePlotFilters);
ko.components.register("curve-points", curvePoints);
ko.components.register("curve-specification", curveSpecification);
ko.components.register("variable-select-multi", variableSelectMulti);
