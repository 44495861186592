import template from "./curve-points.html";
import kognifaiTemplate from "./curve-points-kognifai.html";
import ko from "knockout";
import moment from "moment";
import _ from "underscore";
import CurvePoint from "ViewModels/curves/curvePoint";
import Store from "@/store";

function ViewModel(params) {
    var self = this;
    self.curvePointViewModels = params.curvePointViewModels;
    self.xAxisLogVariable = params.xAxisLogVariable;
    self.yAxisLogVariable = params.yAxisLogVariable;
    self.fromDate = params.fromDate;
    self.toDate = params.toDate;
    self.curveMode = params.curveMode;
    self.curveFit = params.curveFit;

    self.atAnchorXAxis = ko.observable();
    self.newCurvePointX = ko.observable(1);
    self.newCurvePointY = ko.observable(1);

    self.errorText = ko.pureComputed(function() {
        var isNegativeXValueAllowed = self.isNegativeXValueAllowed();
        var isNegativeYValueAllowed = self.isNegativeYValueAllowed();
        var newCurvePointX = self.newCurvePointX();
        var newCurvePointY = self.newCurvePointY();
        var curveFit = self.curveFit();

        var errorText = "Using values less than 1 is not possible with the " + curveFit + " curve fit";

        if (!isNegativeXValueAllowed && newCurvePointX < 1) {
            return errorText;
        } else if (!isNegativeYValueAllowed && newCurvePointY < 1) {
            return errorText;
        } else if (!isNegativeXValueAllowed || !isNegativeYValueAllowed) {
            var curvePointWithError = self.curvePointWithError();

            if (!_.isUndefined(curvePointWithError) && !isNegativeXValueAllowed && curvePointWithError.x() < 1) {
                return errorText;
            } else if (!_.isUndefined(curvePointWithError) && !isNegativeYValueAllowed && curvePointWithError.y() < 1) {
                return errorText;
            }
        }

        return "The curve point must be a number";
    });

    self.hasError = ko.pureComputed(function() {
        var isNewCurvePointValid = self.isNewCurvePointValid();
        var curvePointWithError = self.curvePointWithError();

        if (isNewCurvePointValid) {
            return _.isUndefined(curvePointWithError);
        }

        return isNewCurvePointValid;
    });

    self.curvePointWithError = ko.pureComputed(function() {
        var curvePointViewModels = self.curvePointViewModels();

        return _.find(curvePointViewModels, function(curvePoint) {
            return !curvePoint.isValid();
        });
    });

    self.isNegativeXValueAllowed = ko.pureComputed(function () {
        var curveFit = self.curveFit();

        return curveFit === "Linear" || curveFit === "Polynomial" || curveFit === "Exponential";
    });

    self.isNegativeYValueAllowed = ko.pureComputed(function () {
        var curveFit = self.curveFit();

        return curveFit === "Linear" || curveFit === "Polynomial" || curveFit === "Logarithmic";
    });

    self.isNewCurvePointXValid = ko.pureComputed(function() {
        var newCurvePointX = self.newCurvePointX();
        var xAxisLogVariable = self.xAxisLogVariable();
        var isNegativeXValueAllowed = self.isNegativeXValueAllowed();
        var isFinite = _.isFinite(newCurvePointX);

        if (xAxisLogVariable.name === "time") {
            return true;
        }

        if (!isNegativeXValueAllowed) {
            return isFinite && newCurvePointX > 0;
        }

        return isFinite;
    });

    self.isNewCurvePointYValid = ko.pureComputed(function() {
        var newCurvePointY = self.newCurvePointY();
        var isNegativeYValueAllowed = self.isNegativeYValueAllowed();
        var isFinite = _.isFinite(newCurvePointY);

        if (!isNegativeYValueAllowed) {
            return isFinite && newCurvePointY > 0;
        }

        return isFinite;
    });

    self.isNewCurvePointValid = ko.pureComputed(function() {
        var isNewCurvePointXValid = self.isNewCurvePointXValid();
        var isNewCurvePointYValid = self.isNewCurvePointYValid();
        return isNewCurvePointXValid && isNewCurvePointYValid;
    });

    self.tableOverflowCss = ko.pureComputed(function() {
        var cssClass = "";
        var maxNumberOfRows = 7;

        if (self.curvePointViewModels().length > maxNumberOfRows) {
            cssClass = "curvePointsOverflow";
        }

        return cssClass;
    });

    self.reversedCurvePointViewModels = ko.pureComputed(function () {
        var reversed = _.clone(self.curvePointViewModels());
        return reversed.reverse();
    });

    self.removeCurvePoint = function (curvePointViewModel) {
        self.curvePointViewModels.remove(curvePointViewModel);
    }.bind(self);

    self.yAxisUnitCaption = ko.pureComputed(function() {
        var curveMode = self.curveMode().value;
        var isFuelMode = curveMode === "TimeCharter" || curveMode === "SpotMarket";
        return isFuelMode ? "ton/day" : self.yAxisLogVariable().unit.caption;
    });
}

ViewModel.prototype.addCurvePoint = function() {
    var self = this;
    var x = parseFloat(self.newCurvePointX());
    var y = parseFloat(self.newCurvePointY());
    var curveFit = self.curveFit;

    if (this.xAxisLogVariable().name === "Time") {
        var currentDate = moment.utc();
        var months = self.curvePointViewModels().length;
        currentDate.subtract(months, "months");
        x = currentDate.toDate().getTime();
    }
    var curvePoint = {
        x: x,
        y: y,
    };
    self.curvePointViewModels.push(new CurvePoint(curvePoint, curveFit));
    self.newCurvePointX(1);
    self.newCurvePointY(1);
};

ViewModel.prototype.dispose = function() {
};

var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default { viewModel: ViewModel, template: templateToExport };
