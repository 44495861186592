import _ from "underscore";
import listHelper from "Utilities/curve-list-helper";

function containsTime(list, time) {
    for (var i = 0; i < list.length; i++) {
        if (list[i] === time) {
            return true;
        }
    }
    return false;
}

function getExcludedTimes(excludedVariableList) {
    var excludedTimes = [];
    for (var i = 0; i < excludedVariableList.length; i++) {
        var variable = excludedVariableList[i];
        for (var j = 0; j < variable.excludedData.length; j++) {
            var value = variable.excludedData[j];
            if (!containsTime(excludedTimes, value)) {
                excludedTimes.push(value);
            }
        }
    }
    return excludedTimes;
}

function containsExcluded(list, time) {
    for (var i = 0; i < list.length; i++) {
        if (list[i].time === time) {
            return true;
        }
    }

    return false;
}

function filterDataListToSingleList(filterDataList) {
    var list = [];

    for (var i = 0; i < filterDataList.length; i++) {
        var filterData = filterDataList[i];
        for (var j = 0; j < filterData.dataList.excluded.length; j++) {
            var value = filterData.dataList.excluded[j];
            if (!containsExcluded(list, value.time)) {
                list.push(value.time);
            }
        }
    }

    return list;
}

function excludeDataInTimeRange(variableData, currentDateIndex, nextDateIndex, excludedDataList) {
    for (var i = currentDateIndex; i < nextDateIndex; i++) {
        excludedDataList.push(variableData[i]);
    }
}

function getNextValidDate(variableData, startIndex, operator, limit) {
    for (var i = startIndex; i < variableData.length; i++) {
        if (!compareValueToLimit(operator, variableData[i], limit)) {
            return i;
        }
    }
    return variableData.length;
}

function isTimeRangeOverTimeLimit(variableData, startIndex, stopIndex, filterContinuousLength) {
    var startTime = variableData[startIndex].time;
    stopIndex = stopIndex === variableData.length ? stopIndex - 1 : stopIndex;
    var stopTime = variableData[stopIndex].time;
    var diffTime = stopTime - startTime;

    if (diffTime > filterContinuousLength) {
        return true;
    }
}

function compareValueToLimit(operator, value, limit) {
    if (value) {
        limit = Number(limit);
        if (operator === "<") {
            if (value.data < limit) {
                return true;
            }

        } else if (operator === ">") {
            if (value.data > limit) {
                return true;
            }

        } else if (operator === "=") {
            if (value.data === limit) {
                return true;
            }
        }
    }

    return false;
}

function isValueBetweenTimeRange(value, filter) {
    var fromDate = filter.timeRangeFromDateTimeInMillisecs;
    var toDate = filter.timeRangeToDateTimeInMillisecs;

    return value.time >= fromDate && value.time < toDate;
}

function getExcludedDataFromFilter(variableData, filter) {
    var limit = filter.value;
    var operator = filter.operator;
    var isFilterContinuous = filter.isContinuous;
    var continuousForInMilliseconds = filter.continuousForInMilliseconds;
    var excludedDataList = [];
    var nextDateIndex = -1;
    var currentDateIndex = 0;

    if (isFilterContinuous) {
        for (var i = 0; i < variableData.length; i++) {
            var value = variableData[i];
            if (compareValueToLimit(operator, value, limit)) {
                currentDateIndex = i;
                nextDateIndex = getNextValidDate(variableData, (i + 1), operator, limit);
                var excludedTimeRange = isTimeRangeOverTimeLimit(variableData, currentDateIndex, nextDateIndex, continuousForInMilliseconds);
                if (excludedTimeRange) {
                    excludeDataInTimeRange(variableData, currentDateIndex, nextDateIndex, excludedDataList);
                    i = nextDateIndex - 1;
                }
            }
        }
    } else {
        for (var i = 0; i < variableData.length; i++) {
            var value = variableData[i];
            if (filter.isTimeRangeFilter) {
                if (isValueBetweenTimeRange(value, filter)) {
                    excludedDataList.push(value);
                }
            } else {
                if (compareValueToLimit(operator, value, limit)) {
                    excludedDataList.push(value);
                }
            }
        }
    }

    return { excluded: excludedDataList};
}

function getExcludedVariableData(variableData, variableId, filters, filtersContainsTimeRange) {
    var variableFilters = [];
    var filterDataList = [];
    var filterInfoList = [];
    _.each(filters, function (filter) {
        if (filter.variableId === variableId) {
            variableFilters.push(filter);
        } else if (filter.isTimeRangeFilter && !filtersContainsTimeRange) {
            variableFilters.push(filter);
            filtersContainsTimeRange = true;
        }
    });

    _.each(variableFilters, function (filter) {
        var selectedVariableId = filter ? filter.variableId : -1;
        if (selectedVariableId === variableId || filter.isTimeRangeFilter) {
            var dataList = getExcludedDataFromFilter(variableData, filter);
            filterDataList.push({ dataList: dataList, filter: filter });
        }
    });

    if (filterDataList.length > 0) {
        filterInfoList = filterDataList;
        var excludedData = filterDataListToSingleList(filterDataList);
        filterDataList = excludedData;
    }

    return { data: filterDataList, filterInfoList: filterInfoList, filtersContainsTimeRange: filtersContainsTimeRange };
}

function getExcludedTimesList(filterGroups, variableDataList) {
    var excludedTimes = [];
    var filterInfoList = [];
    var timeRangeFilterTimes = [];

    _.each(filterGroups, function (filters) {
        var filtersContainsTimeRange = false;
        var timeRangeFilterAdded = false;
        var tempExcludedList = [];
        _.each(variableDataList, function (variable) {
            var excludedDataList = getExcludedVariableData(variable.data, variable.id, filters, filtersContainsTimeRange);
            filtersContainsTimeRange = excludedDataList.filtersContainsTimeRange;
            filterInfoList.push(excludedDataList.filterInfoList);
            if (filtersContainsTimeRange && !timeRangeFilterAdded) {
                timeRangeFilterTimes.push(excludedDataList.data);
                timeRangeFilterAdded = true;
            } else {
                tempExcludedList.push({ variable: variable, excludedData: excludedDataList.data });
            }

        });
        var times = getExcludedTimes(tempExcludedList);
        excludedTimes.push(times);
    });

    return { excludedTimes: excludedTimes, timeRangeFilterTimes: timeRangeFilterTimes, filterInfoList: filterInfoList};
}

function addTimesFromTimeRangeFilter(excludedTimes, timeRangeFilters) {
    var times = excludedTimes;
    for (var i = 0; i < timeRangeFilters.length; i++) {
        for (var j = 0; j < timeRangeFilters[i].length; j++) {
            if (!containsTime(excludedTimes, timeRangeFilters[i][j])) {
                times.push(timeRangeFilters[i][j]);
            }
        }

    }
    return times;
}

export default {
    getExcludedTimes: function (filterGroups, variableDataList) {
        var excludedData = getExcludedTimesList(filterGroups, variableDataList);
        var excludedTimes = listHelper.getIntersectionFromMultidimensionalArray(excludedData.excludedTimes);
        excludedTimes = addTimesFromTimeRangeFilter(excludedTimes, excludedData.timeRangeFilterTimes);
        excludedTimes.sort(function (a, b) { return a - b; });

        return { excludedTimes: excludedTimes, filterInfoList: excludedData.filterInfoList };
    },
};

