import template from "./variable-select-multi.html";
import kognifaiTemplate from "./variable-select-multi-kognifai.html";
import ko from "knockout";
import _ from "underscore";
import GraphVariable from "ViewModels/graphVariable";
import Store from "@/store";

function ViewModel(params) {
    var self = this;
    self.variables = params.variables;
    self.initialSelectedVariableIds = ko.utils.unwrapObservable(params.initialSelectedVariableIds) || [];
    self.selectedVariableIds = params.selectedVariableIds;
    self.customCss = params.customCss;

    self.variableViewModels = ko.pureComputed(function() {
        var variables = self.variables();
        var initialSelectedVariableIds = self.initialSelectedVariableIds;

        var viewModels = [];
        _.each(variables,
            function(variable) {
                var variableName = variable.name ? variable.name : variable.description;
                var isSelected = _.contains(initialSelectedVariableIds, variable.id);
                viewModels.push(new GraphVariable(variableName, variable.id, isSelected, initialSelectedVariableIds, undefined, variable.onboardSystem, variable.displayName));
            });
        return viewModels;
    });

    self.selectedVariables = ko.pureComputed(function() {
        var variables = self.variableViewModels();
        var selectedVariables = _.filter(variables,
            function(variable) {
                return variable.isSelected();
            });

        //Update "out" parameter: selectedVariableIds
        if (self.selectedVariableIds) {
            var selectedVariablesByQueue = _.sortBy(selectedVariables, "queue");
            var selectedVariableIds = _.map(selectedVariablesByQueue,
                function(selectedVariable) {
                    return selectedVariable.id;
                });

            self.selectedVariableIds(selectedVariableIds);
        }

        return _.sortBy(selectedVariables, "queue");
    });

    self.unselectedVariables = ko.pureComputed(function() {
        var variables = self.variableViewModels();
        return _.filter(variables,
            function(variable) {
                return !variable.isSelected();
            });
    });
}
var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default { viewModel: ViewModel, template: templateToExport };
