import net from "App/net";
import events from "App/events";
import Store from "@/store";

var CurvePlotsClient = function() {

};

CurvePlotsClient.prototype.find = function(vesselId) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/CurvePlots/Find" : "/CurvePlots/Find";
    var filter = {
        vesselId: vesselId,
    };

    return net.get(url, filter);
};

CurvePlotsClient.prototype.create = function(curvePlot) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/CurvePlots" : "/CurvePlots";
    return net.post(url, curvePlot).done(function(data) {
        events.curvePlotCreated.dispatch(data);
    });
};

CurvePlotsClient.prototype.update = function (curvePlot) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/CurvePlots/" : "/CurvePlots/";

    return net.put(url + curvePlot.id, curvePlot).done(function(data) {
        events.curvePlotUpdated.dispatch(data);
    });
};

CurvePlotsClient.prototype.remove = function(curvePlot) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/CurvePlots/" : "/CurvePlots/";

    return net.del(url + curvePlot.id).done(function() {
        events.curvePlotDeleted.dispatch(curvePlot);
    });
};

export default new CurvePlotsClient();
