import _ from "underscore";
import curvePredictionHelper from "Utilities/curve-prediction-helper";

function sum(timePeriods, propertyName) {
    var sum = 0;
    for (var i = 0; i < timePeriods.length; i++) {
        var timePeriod = timePeriods[i];
        var value = Number(timePeriod[propertyName]);
        if (!_.isNaN(value) && _.isNumber(value)) {
            sum += value;
        }
    }
    return sum;
}

var ViewModel = function (timePeriods, variables, curveMode, xAxisVariableId, yAxisVariableId, curveModule) {
    var self = this;

    self.predictions = [];
    self.curveMode = curveMode;
    self.curveModule = curveModule;

    self.variables = _.map(variables, function(variable) {
        return {
            id: variable.id,
            name: variable.name,
            unit: variable.unit,
        };
    });
    self.xAxisVariableId = xAxisVariableId;
    self.yAxisVariableId = yAxisVariableId;
    self.timePeriods = _.map(timePeriods, function(timePeriod) {
        return timePeriod.getTableData();
    });

    if (curveMode === "TimeCharter") {
        self.totalActualFuelConsumption = sum(self.timePeriods, "actualFuelConsumption");
        self.totalReferenceFuelConsumption = sum(self.timePeriods, "referenceFuelConsumption");
        self.totalDifferenceBetweenConsumptions = sum(self.timePeriods, "differenceBetweenConsumptions");
        self.totalDistanceTravelled = sum(self.timePeriods, "distanceTravelled");
    }

    self.totalIncludedTimeInHours = sum(self.timePeriods, "includedTimeInHours");
    self.totalExcludedTimeInHours = sum(self.timePeriods, "excludedTimeInHours");
};

ViewModel.prototype.addPredictions = function (values, dataPoints, curveFit, regressionOrder) {
    var self = this;
    var predictions = [];
    _.each(values, function (value) {
        var prediction = curvePredictionHelper.predictRegressionValue(value.x, dataPoints, curveFit, regressionOrder);
        predictions.push([value.x, prediction.toFixed(2)]);
    });

    predictions.sort(function (a, b) { return a[0] - b[0]; });

    self.predictions = predictions;
};


export default ViewModel;