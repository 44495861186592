import template from "./curve-plot-filters.html";
import kognifaiTemplate from "./curve-plot-filters-kognifai.html";
import ko from "knockout";
import FilterGroup from "ViewModels/curves/filterGroup";
import events from "App/events";
import Store from "@/store";

function ViewModel(params) {
    var self = this;
    self.filterGroupViewModels = params.filterGroupViewModels;
    self.availableVariables = params.availableVariables;
    self.availableVariablesMap = params.availableVariablesMap;
    self.yAxisLogVariableId = params.yAxisLogVariableId;
    self.curveMode = params.curveMode;

    self.timeRangeFilterAdded = ko.observable(false);

    self.removeFilterGroup = function(filterGroupViewModel) {
        this.filterGroupViewModels.remove(filterGroupViewModel);
    }.bind(self);

    self.filterGroupEmptyBinding = events.filterGroupEmpty.add(function (filterGroupViewModel) {
        if (filterGroupViewModel.timeRangeFilter) {
            self.timeRangeFilterAdded(false);
        }
        self.removeFilterGroup(filterGroupViewModel);
    });
}

ViewModel.prototype.addFilterGroup = function() {
    this.filterGroupViewModels.push(new FilterGroup(this.availableVariablesMap, this.curveMode, this.yAxisLogVariableId, undefined, false));
};

ViewModel.prototype.addTimeRangeFilter = function () {
    this.timeRangeFilterAdded(true);
    this.filterGroupViewModels.push(new FilterGroup(this.availableVariablesMap, this.curveMode, this.yAxisLogVariableId, undefined, true));
};

ViewModel.prototype.dispose = function () {
    this.filterGroupEmptyBinding.detach();
};

var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default { viewModel: ViewModel, template: templateToExport };
