import template from "./curve-details.html";
import kognifaiTemplate from "./curve-details-kognifai.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import events from "App/events";
import FilterGroup from "ViewModels/curves/filterGroup";
import TimePeriod from "ViewModels/curves/curveTimePeriod";
import CurveVariable from "ViewModels/curves/curveVariable";
import CurvePoint from "ViewModels/curves/curvePoint";
import CurvePlotResult from "ViewModels/curves/curvePlotResult";
import dateHelper from "Utilities/date-helper";
import filterHelper from "Utilities/curve-filter-helper";
import curvesClient from "Clients/curves-client";
import logDataClient from "Clients/log-data-client";
import curvePredictionHelper from "Utilities/curve-prediction-helper";
import curveVariableCalculationsHelper from "Utilities/curve-variable-calculations-helper";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, Store);

function ViewModel(params) {
    var self = this;
    self.curveModule = params.curveModule;

    self.curves = Vessels.currentVessel.curves;
    self.variables = Vessels.currentVessel.variables;

    self.millisecondsPerHour = 3600000;

    self.curvePlot = ko.observable();
    self.name = ko.observable();
    self.selectedCurve = ko.observable();
    self.timeConfigurationType = ko.observable();
    self.lastPeriodDays = ko.observable();
    self.setPeriodFromDate = ko.observable();
    self.setPeriodToDate = ko.observable();
    self.setPeriodFromTime = ko.observable();
    self.setPeriodToTime = ko.observable();
    self.periodStartAt = ko.observable();
    self.periodDurationInHours = ko.observable();
    self.dataInterval = ko.observable();

    self.exclusionFilterGroupViewModels = ko.observableArray([]);
    self.timePeriods = ko.observableArray([]);
    self.includedTimePeriods = ko.observableArray([]);
    self.variableDataList = ko.observableArray([]);
    self.curveData = ko.observableArray([]);
    self.curvePointViewModels = ko.observableArray([]);
    self.graphSeries = ko.observableArray([]);
    self.curvePlotResult = ko.observable();
    self.curvePlotReportResult = ko.observable();

    self.selectedTableTab = ko.observable(0);
    self.isInitialized = ko.observable(false);
    self.isLoadError = ko.observable(false);
    self.isVariableDataLoading = ko.observable(true);
    //skip expensive rounding function when it isn't necessary
    self.canSkipRounding = true;
    //this observable is used by kognifai to animate in curve details after it has been rendered
    self.rendered = ko.observable(false);
    if (params.rendered)
        self.rendered = params.rendered;

    self.tableVisible = ko.observable(true);
    //paginated version of includedTimePeriods
    self.tableData = ko.observableArray([]).extend({ paging: null });
    self.toggleTable = function () {
        self.tableVisible(!self.tableVisible());
        //blur the button after click
        if (document.activeElement != document.body)
            document.activeElement.blur();
    };

    self.curveMode = ko.pureComputed(function() {
        var curveMode = self.selectedCurve().curve().curveMode;

        return curveMode;
    });

    self.isXAxisTimeVariable = ko.pureComputed(function () {
        var curve = self.selectedCurve().curve();
        return curve.xAxisLogVariableId === null && curve.yAxisLogVariableId;
    });

    self.referenceCurveDataPoints = ko.pureComputed(function () {
        var curvePointViewModels = self.curvePointViewModels();
        var curveDataPoints;

        if (self.isXAxisTimeVariable()) {
            var selectedFromAndToDates = self.getSelectedFromAndToDates();
            var fromDate = selectedFromAndToDates.fromDate.toDate().getTime();
            var toDate = selectedFromAndToDates.toDate.toDate().getTime();
            var firstCurvePoint = curvePointViewModels[0];
            var secondCurvePoint = curvePointViewModels[1];

            curvePointViewModels = [];
            firstCurvePoint.x(fromDate);
            secondCurvePoint.x(toDate);
            curvePointViewModels.push(firstCurvePoint);
            curvePointViewModels.push(secondCurvePoint);
        }

        curveDataPoints = _.map(curvePointViewModels, function (curvePointViewModel) {
            return curvePointViewModel.dataPoint();
        });

        curveDataPoints.sort(function (a, b) { return a[0] - b[0]; });

        return curveDataPoints;
    });

    self.selectedTimeInHours = ko.pureComputed(function () {
        var hours = 0;
        var timeConfigurationType = self.timeConfigurationType();
        var firstTimePeriod = self.timePeriods()[0];
        var startAt = moment.utc(firstTimePeriod.startDate).format("hh:mm");
        var fromAndToDates = self.getSelectedFromAndToDates(startAt);
        var fromDate = fromAndToDates.fromDate;
        var toDate = fromAndToDates.toDate;

        return toDate.diff(fromDate, "minutes") / 60;
    });

    self.timePeriods = ko.pureComputed(function () {
        var timePeriodId = 0;
        var timePeriods = [];
        var selectedFromAndToDates = self.getSelectedFromAndToDates();
        var selectedFromDate = selectedFromAndToDates.fromDate;
        var selectedToDate = selectedFromAndToDates.toDate;
        var currentTimePeriodDate = selectedFromDate.clone();

        var done = false;
        var isCurrentTimePeriodFirst = self.timeConfigurationType() === "SetPeriod";

        while (!done) {
            var timePeriodStartDate = currentTimePeriodDate.clone();
            var timePeriodEndDate;

            if (isCurrentTimePeriodFirst) {
                var initialDates = self.getInitialTimePeriodDates(currentTimePeriodDate);
                timePeriodStartDate = initialDates.startDate;
                timePeriodEndDate = initialDates.endDate;
                currentTimePeriodDate = _.clone(timePeriodEndDate);
                isCurrentTimePeriodFirst = false;
            } else {
                timePeriodEndDate = currentTimePeriodDate.add(self.periodDurationInHours(), "hours");
            }

            if (timePeriodEndDate.isSameOrAfter(selectedToDate)) {
                timePeriodEndDate = selectedToDate;
                done = true;
            }

            if (done) {
                timePeriods.push(new TimePeriod(timePeriodId, self.curvePlot(), timePeriodStartDate, timePeriodEndDate,
                self.variableDataList(), self.referenceCurveDataPoints(), self.selectedCurve(), true));
            } else {
                timePeriods.push(new TimePeriod(timePeriodId, self.curvePlot(), timePeriodStartDate, timePeriodEndDate,
                self.variableDataList(), self.referenceCurveDataPoints(), self.selectedCurve()));
            }

            timePeriodId ++;
        }

        return timePeriods;
    });

    self.availableVariables = ko.pureComputed(function () {
        var variables = self.variables();
        var selectedCurve = self.selectedCurve();
        if (!selectedCurve) return [];

        var curve = selectedCurve.curve();

        var excludedFilterVariableIds = self.getVariableIdsFromFilters();

        if (Store.getters["User/isKognifai"]) {
            var visibleVariableIds = _.flatten([
                String(curve.xAxisLogVariableId), String(curve.yAxisLogVariableId), _.each(curve.additionaLogVariableIds, function(variableId) {
                    variableId = String(variableId);
                }),
            ]);
        } else {
            var visibleVariableIds = _.flatten([
                curve.xAxisLogVariableId, curve.yAxisLogVariableId, curve.additionaLogVariableIds,
            ]);
        }


        var filterIds = _.difference(excludedFilterVariableIds, visibleVariableIds);
        var combinedVariableIds = _.union(visibleVariableIds, filterIds);
        var availableVariables = [];

        _.each(variables, function (variable) {
            if (self.contains(combinedVariableIds, variable.id)) {
                var visible = self.contains(filterIds, variable.id);
                availableVariables.push({ variable: variable, visible: !visible });
            }
        });

        return availableVariables;
    });

    self.filterGroups = ko.pureComputed(function () {
        var filterGroups = [];
        _.each(self.exclusionFilterGroupViewModels(), function (viewModel) {
            filterGroups.push(viewModel.filterGroup);
        });

        return filterGroups;
    });

    self.timeRangeFrom = ko.pureComputed(function() {
        var fromAndToDates = self.getSelectedFromAndToDates();
        return dateHelper.getFormatedDateTimeString(fromAndToDates.fromDate);
    });

    self.timeRangeTo = ko.pureComputed(function() {
        var fromAndToDates = self.getSelectedFromAndToDates();
        return dateHelper.getFormatedDateTimeString(fromAndToDates.toDate);
    });

    self.curvePlotSelectedBinding = events.curvePlotSpecified.add(function (curvePlot) {
        self.isVariableDataLoading(true);
        self.initializeCurvePlot(curvePlot);
        self.getCurveData();
        self.isInitialized(true);
        self.rendered(false);
    });

    self.yAxisLogVariableId = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve();
        if (!selectedCurve) return null;

        var curve = selectedCurve.curve();
        if (!curve) return null;

        return curve.yAxisLogVariableId;
    });

    self.curveDataArray = ko.pureComputed(function() {
        var curveData = self.curveData();
        if (curveData.length === 0) return [];
        return curvePredictionHelper.getDataArrayFromDataPointObjects(curveData);
    });

    self.curveDataStatistics = ko.pureComputed(function() {
        var curveDataArray = self.curveDataArray();
        var mean = curvePredictionHelper.getMean(curveDataArray);
        var std = curvePredictionHelper.getStd(curveDataArray, mean);
        var N = curveDataArray.length;

        var curveDataStatistics = {
            mean: mean,
            std: std,
            N: N,
        };
        return curveDataStatistics;
    });

    self.curveDataXMin = ko.pureComputed(function() {
        var curveDataArray = self.curveDataArray();
        if (_.isEmpty(curveDataArray)) return null;

        return _.min(curveDataArray, function(point) {
             return point[0];
        })[0];
    });

    self.curveDataXMax = ko.pureComputed(function() {
        var curveDataArray = self.curveDataArray();
        if (_.isEmpty(curveDataArray)) return null;

        return _.max(curveDataArray, function(point) {
            return point[0];
        })[0];
    });

    self.referenceCurveXMin = ko.pureComputed(function() {
        var referenceCurveDataPoints = self.referenceCurveDataPoints();
        if (_.isEmpty(referenceCurveDataPoints)) return null;

        return _.min(referenceCurveDataPoints, function(point) {
            return point[0];
        })[0];
    });

    self.referenceCurveXMax = ko.pureComputed(function() {
        var referenceCurveDataPoints = self.referenceCurveDataPoints();
        if (_.isEmpty(referenceCurveDataPoints)) return null;

        return _.max(referenceCurveDataPoints, function(point) {
            return point[0];
        })[0];
    });

    self.XMin = ko.pureComputed(function() {
        var curveDataXMin = self.curveDataXMin();
        var referenceCurveXMin = self.referenceCurveXMin();

        if (_.isFinite(curveDataXMin) && !_.isFinite(referenceCurveXMin)) return curveDataXMin;
        if (!_.isFinite(curveDataXMin) && _.isFinite(referenceCurveXMin)) return referenceCurveXMin;

        return referenceCurveXMin < curveDataXMin ? referenceCurveXMin : curveDataXMin;
    });

    self.XMax = ko.pureComputed(function() {
        var curveDataXMax = self.curveDataXMax();
        var referenceCurveXMax = self.referenceCurveXMax();

        if (_.isFinite(curveDataXMax) && !_.isFinite(referenceCurveXMax)) return curveDataXMax;
        if (!_.isFinite(curveDataXMax) && _.isFinite(referenceCurveXMax)) return referenceCurveXMax;

        return referenceCurveXMax > curveDataXMax ? referenceCurveXMax : curveDataXMax;
    });

    self.curveFit = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve();
        var curve = selectedCurve.curve();
        return curve.curveFit;
    });

    self.regressionOrder = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve();
        var curve = selectedCurve.curve();
        return curve.regressionOrder;
    });

    self.curveRegressionData = ko.pureComputed(function() {
        var curveDataArray = self.curveDataArray();
        var curveFit = self.curveFit();
        var regressionOrder = self.regressionOrder();

        if (curveDataArray.length < 2) return null;

        return curvePredictionHelper.getRegressionData(curveDataArray, curveFit, regressionOrder);
    });
}

ViewModel.prototype.setSelectedTableTab = function (data, event) {
    var tab = this.selectedTableTab();

    if (event.target.id === "curveTableTab0") {
        tab = 0;
    } else if (event.target.id === "curveTableTab1") {
        tab = 1;
    } else if (event.target.id === "curveTableTab2") {
        tab = 2;
    }

    this.selectedTableTab(tab);
};

ViewModel.prototype.showFilterInfoTooltip = function (vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: vm.timeRangeComment, customCssClass: "" }, element);
};

ViewModel.prototype.closeToopltip = function () {
    events.closeTooltip.dispatch();
};

ViewModel.prototype.getVariableIdsFromFilters = function () {
    var self = this;
    var variableIds = [];
    _.each(this.filterGroups(), function (filterGroup) {
        _.each(filterGroup, function (filter) {
            if (!filter.timeRangeFilter && !self.contains(variableIds, filter.variableId)) {
                if (Store.getters["User/isKognifai"]) {
                    variableIds.push(String(filter.variableId));
                } else {
                    variableIds.push(filter.variableId);
                }
            }
        });
    });

    return variableIds;
};

ViewModel.prototype.getInitialTimePeriodDates = function (currentDate) {
    var self = this;
    var startDate;
    var endDate;
    var startTimeInSeconds = timeStringInSeconds(self.setPeriodFromTime());
    var periodStartAtInSeconds = timeStringInSeconds(self.periodStartAt());
    var difference = periodStartAtInSeconds - startTimeInSeconds;

    startDate = dateHelper
        .getDateTime(self.setPeriodFromDate(), self.setPeriodFromTime());

    if (difference > 0) {
        endDate = startDate.clone().add(difference, "seconds");
    } else {
        var periodDurationInSeconds = hoursToSeconds(self.periodDurationInHours());
        var currentTimePeriodDuration = periodDurationInSeconds + difference;
        endDate = startDate.clone().add(currentTimePeriodDuration, "seconds");
        currentDate.add(self.periodDurationInHours(), "hours");
    }

    return { startDate: startDate, endDate: endDate };
};

ViewModel.prototype.getSelectedFromAndToDates = function (periodStartAt) {
    var fromDate = moment.utc().seconds(0).milliseconds(0);
    var toDate = moment.utc().seconds(0).milliseconds(0);

    if (this.timeConfigurationType() === "SetPeriod") {
        fromDate = dateHelper.getDateTime(this.setPeriodFromDate(), this.setPeriodFromTime());
        toDate = dateHelper.getDateTime(this.setPeriodToDate(), this.setPeriodToTime());
    } else {
        var periodStartAtSplit = this.periodStartAt().split(":");
        var periodStartAtHours = periodStartAtSplit[0];
        var periodStartAtMinutes = periodStartAtSplit[1];
        fromDate.hours(periodStartAtHours);
        fromDate.minutes(periodStartAtMinutes);
        fromDate.subtract(this.lastPeriodDays(), "days");
    }

    return { fromDate: fromDate, toDate: toDate };
};

ViewModel.prototype.roundDownDate = function(date) {
    var dataInterval = this.dataInterval().toLowerCase();
    var newDate = date.clone();

    var minutes = newDate.minutes();
    if (dataInterval === "minute") {
        return date;
    } else if (dataInterval === "quarterhour" || dataInterval === "quarter") {
        newDate.subtract(minutes % 15, "minutes");
        newDate.second(0);
        newDate.millisecond(0);
    } else if (dataInterval === "tenminutes") {
        newDate.subtract(minutes % 10, "minutes");
        newDate.second(0);
        newDate.millisecond(0);
    } else if (dataInterval === "hour") {
        newDate = newDate.startOf("hour");
    }

    return newDate;
};

ViewModel.prototype.getCurveVariables = function () {
    var self = this;
    var curveVariables = [];
    var availableVariables = self.availableVariables();
    var granularity = self.dataInterval();

    _.each(availableVariables, function (availableVariable) {
        var variable = availableVariable.variable;
        var fromAndToDates = self.getSelectedFromAndToDates(self.setPeriodFromTime);
        var fromDate = self.roundDownDate(fromAndToDates.fromDate);

        fromDate = fromDate.format("YYYY-MM-DDTHH:mm");
        var toDate = fromAndToDates.toDate.format("YYYY-MM-DDTHH:mm");

        curveVariables.push({ logVariableId: variable.id, fromDate: fromDate, toDate: toDate, granularity: granularity });
    });

    return curveVariables;
};

ViewModel.prototype.getCurveData = function () {
    var self = this;
    var curveVariables = this.getCurveVariables();
    var xAxisId = this.selectedCurve().curve().xAxisLogVariableId;
    var yAxisId = this.selectedCurve().curve().yAxisLogVariableId;
    var tmpVariableDataList = [];
    events.updateLoaderText.dispatch("Loading data");
    this.getLogVariableData(curveVariables).done(function(variableDataList) {
        self.pushDataToVariabledataList(variableDataList);
        self.updateVariablesWithData();
    }).fail(function () {
        self.isLoadError(true);
    }).always(function() {
        self.isVariableDataLoading(false);
        //push to bottom of call stack so the animation is triggered after elements are added to dom
        setTimeout(function() {
            self.rendered(true);
        }, 0);
    });
};

ViewModel.prototype.getLogVariableData = function(curveVariables) {
    var self = this;
    var granularity = curveVariables[0].granularity;
    var variableDataList = [];
    var continuationFilters = [];
    var dateTimeFormat = "UnixEpochMs";
    var yAxisLogVariableId = this.selectedCurve().curve().yAxisLogVariableId;
    var curveMode = self.curveMode();
    var isFuelCurveMode = curveMode === "TimeCharter" || curveMode === "SpotMarket";

    var deferred = $.Deferred();

    _.each(curveVariables, function (curveVariable) {
        // If curve mode is Time Charter or Spot Market, the y-axis has to be in ton/day unit
        var unitName = isFuelCurveMode && curveVariable.logVariableId === yAxisLogVariableId ? "Ton(m)/Day" : null;
        logDataClient.findLogData(curveVariable.logVariableId, curveVariable.fromDate, curveVariable.toDate, granularity, dateTimeFormat, unitName).done(function(logData) {
            var logVariableId = logData.filter.logVariableId;
            var logVariableName = self.getVariableName(logVariableId);
            var logVariableDisplayName = self.getVariableDisplayName(logVariableId);
            var data = logData.data;

            // Update variable's unit
            var variable = _.find(self.variables(), function(variable) {
                return variable.id === logVariableId;
            });
            variable.unit = logData.unit;

            variableDataList.push(new CurveVariable(logVariableId, data, [], 0, logVariableName, logVariableDisplayName));

            if (variableDataList.length === curveVariables.length) {
                deferred.resolve(variableDataList);
            }
        }).fail(function() {
            deferred.reject(arguments);
        });
    });

    return deferred.promise();
};

ViewModel.prototype.pushDataToVariabledataList = function (updatedVariableDataList) {
    var self = this;
    var variableDataList = this.variableDataList();

    if (variableDataList.length === 0) {
        this.variableDataList(updatedVariableDataList);
    } else {
        // TODO: Will we ever go here? Doesn't seem like :/
        for (var i = 0; i < updatedVariableDataList.length; i++) {
            var updatedVariable = updatedVariableDataList[i];
            var variable = self.findVariable(variableDataList, updatedVariable.id);

            if (variable) {
                extendList(variable.data, updatedVariable.data);
            } else {
                variableDataList.push(updatedVariable);
            }
        }
    }
};

ViewModel.prototype.updateVariablesWithData = function () {
    var self = this;
    var xAxisId = this.selectedCurve().curve().xAxisLogVariableId;
    var yAxisId = this.selectedCurve().curve().yAxisLogVariableId;
    var excludedLists = self.getExcludedTimes();
    var excludedTimes = excludedLists.excludedTimes;
    var filterInfoList = excludedLists.filterInfoList;

    var visibleVariables = self.getVisibleVariables();
    self.addVariablesToTimePeriods(visibleVariables, filterInfoList);
    self.addVariableData(excludedTimes, visibleVariables);

    self.calculateAverages();
    self.pushAveragesToPlotGraph(xAxisId, yAxisId);
};

ViewModel.prototype.getVisibleVariables = function () {
    var self = this;
    var variableList = [];
    var variableDataList = this.variableDataList();
    var availableVariables = this.availableVariables();

    variableList = _.filter(variableDataList, function (variable) {
        var availableVariable = self.findVariable(availableVariables, variable.id);
        if (availableVariable && availableVariable.visible) {
            variable.unit = availableVariable.variable.unit;
            return availableVariable.visible;
        }

    });
    return variableList;
};

ViewModel.prototype.getExcludedTimes = function () {
    var self = this;
    var variableDataList = this.variableDataList();
    var filterGroups = this.filterGroups();
    if (Store.getters["User/isKognifai"]) {
        _.each(filterGroups, function (filterGroup) {
            _.each(filterGroup, function (filterGroupVariable) {
                filterGroupVariable.variableId = String(filterGroupVariable.variableId);
            });
        });
    }
    var excludedData = filterHelper.getExcludedTimes(filterGroups, variableDataList);

    return { excludedTimes: excludedData.excludedTimes, filterInfoList: excludedData.filterInfoList };
};

ViewModel.prototype.getVariableName = function(logVariableId) {
    var availableVariables = this.availableVariables();
    var variable = this.findVariable(availableVariables, logVariableId);

    return variable.variable.name;
};

ViewModel.prototype.getVariableDisplayName = function(logVariableId) {
  var availableVariables = this.availableVariables();
  var variable = this.findVariable(availableVariables, logVariableId);

  return variable.variable.displayName ?? variable.variable.name;
};

ViewModel.prototype.calculateAverages = function () {
    var timePeriods = this.timePeriods();
    for (var i = 0; i < timePeriods.length; i++) {
        timePeriods[i].calculateAverageData();
    }
};

ViewModel.prototype.pushAveragesToPlotGraph = function (variableX, variableY) {
    var self = this;
    var timePeriods = this.timePeriods();
    var curveMode = self.curveMode();
    var curveModule = self.curveModule();
    var curveData = [];
    for (var i = 0; i < timePeriods.length; i++) {
        var timePeriod = timePeriods[i];
        if (!timePeriod.isTimePeriodExcluded()) {
            if (self.isXAxisTimeVariable()) {
                curveData.push({ x: timePeriod.startDate, y: timePeriod.yAxisVariable().average, timePeriod: timePeriod });
            }
            else if (!timePeriod.xAxisVariable()) {
                curveData.push({ x: new Date().getTime(), y: timePeriod.yAxisVariable().average, timePeriod: timePeriod });
            } else {
                curveData.push({ x: timePeriod.xAxisVariable().average, y: timePeriod.yAxisVariable().average, timePeriod: timePeriod });
            }
        }
        self.includedTimePeriods.push(timePeriod);
    }
    self.tableData(self.includedTimePeriods());
    self.curveData(curveData);

    self.curvePlotResult(new CurvePlotResult(self.includedTimePeriods(), self.getVisibleVariables(), curveMode, variableX, variableY, curveModule));
};

ViewModel.prototype.initializeCurvePlot = function (curvePlot) {
    var self = this;
    var isDataIntervalQuarter = curvePlot.dataInterval === "Quarter";
    var dataInterval = isDataIntervalQuarter ? "quarterHour" : curvePlot.dataInterval;
    var isDataIntervalTenMinutes = curvePlot.dataInterval === "tenminutes";
    dataInterval = isDataIntervalTenMinutes ? "TenMinutes" : dataInterval;
    self.curveData([]);
    self.includedTimePeriods([]);
    self.variableDataList([]);
    self.selectedTableTab(0);

    self.curvePlot(curvePlot);
    self.name(curvePlot.name);
    self.dataInterval(dataInterval);

    var curveViewModels = self.curves();
    self.selectedCurve(_.find(curveViewModels, function (curveViewModel) {
        return curveViewModel.curve().id === curvePlot.curveId;
    }));

    var curvePointViewModels = _.map(self.selectedCurve().curve().points, function (point) {
        return new CurvePoint(point);
    });
    self.curvePointViewModels(curvePointViewModels);

    self.timeConfigurationType(curvePlot.timeConfigurationType);
    self.lastPeriodDays(curvePlot.lastPeriodInSeconds / 24 / 60 / 60);

    self.setPeriodFromDate(dateHelper.getFormatedDateString(curvePlot.setPeriodFromDate));
    self.setPeriodToDate(dateHelper.getFormatedDateString(curvePlot.setPeriodToDate));

    self.setPeriodFromTime(curvePlot.setPeriodFromTime);
    self.setPeriodToTime(curvePlot.setPeriodToTime);

    self.periodStartAt(curvePlot.periodStartAt);
    self.checkSkipRounding();
    self.periodDurationInHours(curvePlot.periodDurationInHours);
    //For future reference, in kognifai, we may add one average plot per 6 or 12 hours,
    // in that case, we can add autodetect/set dataInterval function here

    var curveMode = self.selectedCurve().curve().curveMode;
    var exclusionFilterGroupViewModels = _.map(curvePlot.exclusionFilters,
        function (exclusionFilterGroup) {
            return new FilterGroup(self.availableVariablesMap, curveMode, self.yAxisLogVariableId, exclusionFilterGroup);
        });
    self.exclusionFilterGroupViewModels(exclusionFilterGroupViewModels);
};

ViewModel.prototype.addVariablesToTimePeriods = function (variables, filterInfoList) {
    var timePeriods = this.timePeriods();
    for (var i = 0; i < timePeriods.length; i++) {
        timePeriods[i].addVariables(variables, filterInfoList);
    }
};

ViewModel.prototype.addVariableData = function (excludedTimes, variableDataList) {
    var self = this;
    var granularityAsHours = (curveVariableCalculationsHelper.getGranularityAsMillisecond(self.dataInterval())) / self.millisecondsPerHour;
    var timeSpanStart = self.timePeriods()[0].startDate;
    var timeSpanEnd = self.timePeriods()[self.timePeriods().length - 1].endDate;

    for (var i = 0; i < variableDataList.length; i++) {
        var variable = variableDataList[i];
        if (variable.data.length > 0) {
            var startingIndexFactor = 0;

            var endIndex = Math.ceil(granularityAsHours / self.periodDurationInHours() + 1);

            var previousDataTime = variable.data[0].time;

            for (var j = 0; j < variable.data.length; j++) {
                var data = variable.data[j];
                var dataTime = data.time;

                //If there's a missing data gap
                if (dataTime - previousDataTime > self.periodDurationInHours() * self.millisecondsPerHour) {
                    endIndex = self.timePeriods().length;
                }

                if (j > 0) {
                    startingIndexFactor = self.getStartingIndexFactor(data.time, timeSpanStart, timeSpanEnd);
                }

                var currentTimePeriods = self.addVariablesToApplicableTimePeriods(data, variable.id, self.dataInterval(), startingIndexFactor, endIndex, excludedTimes);
                endIndex = currentTimePeriods.endIndex;
                previousDataTime = data.time;
            }
        }
    }
};

ViewModel.prototype.getStartingIndexFactor = function (dataTime, timeSpanStart, timeSpanEnd) {
    var timeSpanDuration = timeSpanEnd - timeSpanStart;
    var dataTimeIntoTimeSpan = dataTime - timeSpanStart;
    return dataTimeIntoTimeSpan / timeSpanDuration;
};

ViewModel.prototype.getFirstValidDataPoint = function (data) {
    var timePeriod = this.timePeriods()[0];
    for (var i = 0; i < data.length; i++) {
        if (data[i].time >= timePeriod.startDate) {
            return data[i];
        }
    }
};

ViewModel.prototype.getInitialTimePeriod = function (time) {
    var self = this;
    var timePeriods = this.timePeriods();
    for (var i = 0; i < timePeriods.length; i++) {
        var timePeriod = timePeriods[i];
        var startDate;
        //When period starts at XX:00 and granularity is 1 hour, there is no point
        //running the very expensive roundDownTimeInMilliseconds.
        //8000 timePeriods takes 16 seconds with and 1 second without this function
        if (self.canSkipRounding)
            startDate = timePeriod.startDate;
        else
            startDate = self.roundDownTimeInMilliseconds(timePeriod.startDate);

        if (time >= startDate && time < timePeriod.endDate) {
            return {timePeriod: timePeriod, timePeriodIndex: i};
        }
    }
};

ViewModel.prototype.addVariablesToApplicableTimePeriods = function (data, variableId, granularity, startingIndexFactor, endIndex, excludedTimes) {
    var self = this;
    var granularityAsHours = (curveVariableCalculationsHelper.getGranularityAsMillisecond(self.dataInterval())) / self.millisecondsPerHour;
    var timePeriods = this.timePeriods();
    var granularityAsMilliseconds = curveVariableCalculationsHelper.getGranularityAsMillisecond(granularity);

    var range = Math.ceil(granularityAsHours / self.periodDurationInHours() + 1);

    if (endIndex > timePeriods.length) {
        endIndex = timePeriods.length;
    }

    var startingIndex = Math.floor( startingIndexFactor * ( self.timePeriods().length - 1 ) );

    for (var i = startingIndex; i < endIndex; i++) {
        var timePeriod = timePeriods[i];
        var dataTime = self.roundDownTimeInMilliseconds(+data.time);

        if (timePeriod) {
            var isDataWithinTimePeriod = self.isDataWithinTimePeriod(dataTime, granularityAsMilliseconds, timePeriod);
        }

        if (isDataWithinTimePeriod) {
            var isExcluded = self.isDataExcluded(excludedTimes, data);
            timePeriod.addVariableData(data, variableId, isExcluded);
            startingIndex = i;
        }
    }

    return { endIndex: startingIndex + range };
};

ViewModel.prototype.isDataWithinTimePeriod = function (dataTime, granularityAsMilliseconds, timePeriod) {
    var isPartOfDataWithin = dataTime >= timePeriod.startDate && dataTime < timePeriod.endDate;
    var isDataOverlappingEntirePeriod = dataTime <= timePeriod.startDate && (+dataTime + granularityAsMilliseconds) >= timePeriod.endDate;
    var isDataEncompassed = (dataTime + granularityAsMilliseconds) > timePeriod.startDate && (dataTime + granularityAsMilliseconds) < timePeriod.endDate;
    return isPartOfDataWithin || isDataOverlappingEntirePeriod || isDataEncompassed;
};

ViewModel.prototype.roundDownTimeInMilliseconds = function(milliseconds) {
    var date = moment.utc(milliseconds);
    date = this.roundDownDate(date);
    return date.toDate().getTime();
};

ViewModel.prototype.isDataExcluded = function (excludedTimes, value) {
    for (var i = 0; i < excludedTimes.length; i++) {
        if (excludedTimes[i] === value.time) {
            return true;
        }
    }
    return false;
};

ViewModel.prototype.findVariable = function (variables, id) {
    for (var i = 0; i < variables.length; i++) {
        var variable = variables[i];
        if (variable.variable) {
            if (variable.variable.id === id) {
                return variable;
            }
        } else {
            if (variable[i].id === id) {
                return variable;
            }
        }
    }
};

ViewModel.prototype.containsExcludedTime = function (list, time, startIndex, endIndex) {
    if (list.length === 0) {
        return false;
    }
    if (startIndex < 0) {
        return false;
    }
    for (var i = startIndex; i < endIndex; i++) {
        if (list[i] === time) {
            return true;
        }
    }
};

ViewModel.prototype.contains = function(list, obj) {
    for (var i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }
    return false;
};

ViewModel.prototype.extendList = function (listToExtend, secondList) {
    for (var i = 0; i < secondList.length; i++) {
        listToExtend.push(secondList[i]);
    }
};

ViewModel.prototype.getExcelExport = function() {
    var curvePlotResult = this.curvePlotResult();
    var curveDataArray = this.curveDataArray();
    var curve = this.selectedCurve().curve();

    curvePlotResult.addPredictions(curve.points, curveDataArray, curve.curveFit, curve.regressionOrder);
    curvePlotResult.curvePlotReportResult = this.getCurvePlotReportResult();

    curvesClient.downloadExcelReport(curvePlotResult);
};

ViewModel.prototype.getCurvePlotReportResult = function () {
    var curve = this.selectedCurve().curve();

    var curvePlotReportResult = {
        fromDate: this.timeRangeFrom(),
        toDate: this.timeRangeTo(),
        selectedTimeInHours: this.selectedTimeInHours(),
        referenceCurveDataPoints: this.referenceCurveDataPoints(),
        graphSeries: this.graphSeries(),
        filterDescriptions: this.getFilterDescriptions(),
        curveName: curve.name,
        referenceValue: curve.referenceValue,
        exponent: curve.exponent,
    };

    return curvePlotReportResult;
};

ViewModel.prototype.getFilterDescriptions = function() {
    var filterGroups = this.filterGroups();

    var filterDescriptions = [];
    _.each(filterGroups, function(filterGroup) {
        var descriptions = _.map(filterGroup, function (filter) {
            if (filter.isTimeRangeFilter) {
                var formatedStartDate = filter.formatedStartDate;
                var formatedEndDate = filter.formatedEndDate;

                return {
                    description: "From date: " + formatedStartDate + " To date: " + formatedEndDate,
                    comment: filter.timeRangeComment,
                };
            }

            return { description: filter.filterDescription };
        });

        filterDescriptions.push(descriptions);
    });

    return filterDescriptions;
};

ViewModel.prototype.checkSkipRounding = function () {
    if (this.periodStartAt().split(":")[1] !== "00"
    || this.dataInterval().toLowerCase() !== "hour")
        this.canSkipRounding = false;
};

ViewModel.prototype.dispose = function () {
    this.curvePlotSelectedBinding.detach();
};

function timeStringInSeconds(timeString) {
    var times = timeString.split(":");
    var hours = Number(times[0]);
    var minutes = Number(times[1]);

    var seconds = (hours * 60 * 60) + (minutes * 60);

    return seconds;
}

function hoursToSeconds(hour) {
    hour = Number(hour);

    return hour * 60 * 60;
}

var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default {viewModel: ViewModel, template: templateToExport};
