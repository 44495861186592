import template from "./curves.html";
import kognifaiTemplate from "./curves-kognifai.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import logVariablesClient from "Clients/log-variables-client";
import curvesClient from "Clients/curves-client";
import curvePlotsClient from "Clients/curve-plots-client";
import CurveVM from "ViewModels/curves/curve";
import CurvePlot from "ViewModels/curves/curvePlot";
import queryStringHelper from "Utilities/query-string-helper";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, Store);

function ViewModel(params) {
    var self = this;
    var vesselId = Vessels.currentVessel.id;
    self.curveModule = ko.observable(params.curveModule);
    self.detailsRendered  = ko.observable(false);
    self.showLoader = ko.observable(false);
    self.detailsRenderedBinding = self.detailsRendered.subscribe(function(value) {
        if (value)
            self.showLoader(false);
    });
    self.menuOpen = ko.observable(true);

    self.toggleMenu = function () {
        self.menuOpen(!self.menuOpen());
        //blur the button after click
        if (document.activeElement != document.body)
            document.activeElement.blur();
    };

    self.curveModes = ko.observableArray([
        { name: "Time charter", value: "TimeCharter" }, { name: "Spot market", value: "SpotMarket" },
    ]);

    self.isLoadError = ko.observable(false);
    self.hasCurveBeenUpdated = ko.observable(false);
    self.isCurvePlotSpecified = ko.observable(false);

    self.curveMode = ko.observable(self.getInitialCurveMode());

    self.isCurvesLoading = ko.observable(true);
    Vessels.currentVessel.curves = Vessels.currentVessel.curves || ko.observableArray([]);
    self.curves = Vessels.currentVessel.curves;

    self.curvesInCurveMode = ko.pureComputed(function () {
        var curves = self.curves();
        var curveMode = self.curveMode();
        return _.filter(curves, function (curve) {
            return curve.curve().curveMode === curveMode.value;
        });
    });

    curvesClient.find(vesselId).done(function (data) {
        if (Store.getters["User/isKognifai"]) {
            _.each(data, function (curve) {
                curve.vesselId = String(curve.vesselId);
                curve.xAxisLogVariableId = String(curve.xAxisLogVariableId);
                curve.yAxisLogVariableId = String(curve.yAxisLogVariableId);
            });
        }
        self.curves(_.map(data, function(curve) { return new CurveVM(curve); }));
    }).fail(function() {
        self.isLoadError(true);
    }).always(function() {
        self.isCurvesLoading(false);
    });

    self.isVariablesLoading = ko.observable(true);
    Vessels.currentVessel.variables = Vessels.currentVessel.variables || ko.observableArray([]);
    self.variables = Vessels.currentVessel.variables;

    logVariablesClient.findAvailableLogVariablesExtended(vesselId).done(function(data) {
        self.variables(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isVariablesLoading(false);
    });

    self.isMassFlowRateVariablesLoading = ko.observable(true);
    Vessels.currentVessel.massFlowRateVariables = Vessels.currentVessel.massFlowRateVariables || ko.observableArray([]);
    self.massFlowRateVariables = Vessels.currentVessel.massFlowRateVariables;

    logVariablesClient.findMassFlowRateVariables(vesselId).done(function(data) {
        self.massFlowRateVariables(data);
    }).fail(function() {
        self.isLoadError(true);
    }).always(function() {
        self.isMassFlowRateVariablesLoading(false);
    });

    self.isSpeedVariablesLoading = ko.observable(true);
    Vessels.currentVessel.speedVariables = Vessels.currentVessel.speedVariables || ko.observableArray([]);
    self.speedVariables = Vessels.currentVessel.speedVariables;

    logVariablesClient.findSpeedVariables(vesselId).done(function (data) {
        self.speedVariables(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isSpeedVariablesLoading(false);
    });

    self.isCurvePlotsLoading = ko.observable(true);
    Vessels.currentVessel.curvePlots = Vessels.currentVessel.curvePlots || ko.observableArray([]);
    self.curvePlots = Vessels.currentVessel.curvePlots;

    curvePlotsClient.find(vesselId).done(function (data) {
        self.curvePlots(_.map(data, function(curvePlot) { return new CurvePlot(curvePlot); }));
    }).fail(function() {
        self.isLoadError(true);
    }).always(function() {
        self.isCurvePlotsLoading(false);
    });

    self.isLoading = ko.pureComputed(function() {
        return self.isVariablesLoading() ||
            self.isCurvesLoading() ||
            self.isCurvePlotsLoading() ||
            self.isMassFlowRateVariablesLoading() ||
            self.isSpeedVariablesLoading();
    });

    self.curveCreatedBinding = events.curveCreated.add(function(curve) {
        self.curves.push(new CurveVM(curve));
    });

    self.presetChangedBinding = events.presetChanged.add(function() {
        self.hasCurveBeenUpdated(true);
    });

    self.curveDeletedBinding = events.curveDeleted.add(function(curve) {
        self.curves.remove(function(curveViewModel) {
            return curveViewModel.curve().id === curve.id;
        });
    });

    self.editCurveViewModel = ko.observable();
    self.editCurveStartBinding = events.editCurveStart.add(function (curveViewModel) {
        self.editCurveViewModel(curveViewModel);
    });

    self.editCurveDoneBinding = events.editCurveDone.add(function (curveViewModel) {
        if (curveViewModel && self.isCurvePlotSpecified()) {
            self.hasCurveBeenUpdated(true);
        }
        self.editCurveViewModel(null);
    });

    self.specifiedCurvePlot = ko.observable();
    self.curvePlotSpecifiedBinding = events.curvePlotSpecified.add(function(curvePlot) {
        self.specifiedCurvePlot(curvePlot);
        self.hasCurveBeenUpdated(false);
        self.isCurvePlotSpecified(true);
        self.menuOpen(false);
        self.showLoader(true);
    });
}

ViewModel.prototype.getInitialCurveMode = function () {
    var curveModule = this.curveModule();

    if (curveModule === "DataAnalysis") {
        return { name: "Custom comparison", value: "Custom" };
    }

    return this.curveModes()[0];
};

ViewModel.prototype.dispose = function() {
    this.curveCreatedBinding.detach();
    this.curveDeletedBinding.detach();
    this.presetChangedBinding.detach();
    this.curvePlotSpecifiedBinding.detach();
    this.editCurveStartBinding.detach();
    this.editCurveDoneBinding.detach();
    this.detailsRenderedBinding.dispose();
};


var templateToExport = Store.getters["User/isKognifai"] ? kognifaiTemplate : template;

export default {viewModel: ViewModel, template: templateToExport};
