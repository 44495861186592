import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import dateHelper from "Utilities/date-helper";
import timeConverter from "Utilities/time-converter-helper";
import variableCalculations from "Utilities/curve-variable-calculations-helper";
import curvePredictionHelper from "Utilities/curve-prediction-helper";
import CpModule from "ViewModels/curves/cpModule";
import Store from "@/store";

function TimePeriod(id, curvePlot, startDate, endDate, variableDataList, referenceCurveDataPoints, selectedCurve) {
    var self = this;

    self.isKognifai = Store.getters["User/isKognifai"];

    self.hourInMilliseconds = 3600000;

    self.selectedCurve = selectedCurve.curve();
    self.curvePlot = curvePlot;
    self.id = id;
    self.startDate = startDate.toDate().getTime();
    self.endDate = endDate.toDate().getTime();
    self.referenceCurveDataPoints = referenceCurveDataPoints;

    self.dataInterval = curvePlot.dataInterval;

    self.variableDataList = ko.observableArray([]);
    self.filterInfoList = ko.observableArray([]);
    self.includedTimeInHours = ko.observable(0);
    self.excludedTimeInHours = ko.observable(0);
    self.distanceTravelled = ko.observable(0);

    self.consumptionText = ko.pureComputed(function () {
        var curveMode = self.selectedCurve.curveMode;

        if (curveMode === "TimeCharter") {
            return self.getDeviationFromReferenceYAxisString();
        } else if (curveMode === "SpotMarket") {
            return self.extrapolatedConsumption();
        }

        return "";
    });

    self.extrapolatedConsumption = ko.pureComputed(function() {
        var curve = self.selectedCurve;
        var xAxisVariable = self.xAxisVariable();
        var yAxisVariable = self.yAxisVariable();
        var referenceValue = curve.referenceValue;
        var exponent = curve.exponent;

        if (curve.curveMode !== "SpotMarket") {
            return 0;
        }

        var result = 0;
        var dataPoints = 0;

        for (var i = 0; i < xAxisVariable.data.length; i++) {
            var xAxisData = xAxisVariable.data[i].data;
            var yAxisData = yAxisVariable.data[i].data;

            if (xAxisData === 0) {
                continue;
            }

            var extrapolatedData = yAxisData * Math.pow((referenceValue / xAxisData), exponent);
            if (_.isNaN(extrapolatedData)) {
                continue;
            }
            dataPoints ++;
            result += extrapolatedData;
        }

        result = result / dataPoints;

        return _.isNaN(result) ? "No data" : result.toFixed(2);
    });

    self.timeMultiplierInSeconds = ko.pureComputed(function () {
        var dataInterval = self.dataInterval.toLowerCase();
        switch (dataInterval) {
            case "minute":
                return 60;
            case "tenminutes":
                return 60 * 10;
            case "quarter":
            case "quarterHour":
                return 60 * 15;
            case "hour":
                return 60 * 60;
            default:
                return 60;
        }
    });

    self.isTimeSpanLessThanDataInterval = ko.pureComputed(function () {
        var timePeriodTimeSpanInSeconds = self.getTimeDifferenceInHours(self.endDate, self.startDate, true) * 60 * 60;

        return timePeriodTimeSpanInSeconds < self.timeMultiplierInSeconds();
    });

    self.excludedTimeInHours = ko.pureComputed(function() {
        var variable = self.variableDataList()[0];
        if (!variable) {
            return 0;
        }
        var time = self.timeMultiplierInSeconds() * variable.excludedData.length;
        return time / 3600;
    });

    self.excludedDataInfoText = ko.pureComputed(function () {
        var currentSampleSize = self.currentSampleSize();
        var excludedSampleSize = 100 - currentSampleSize;
        var infoText = "";
        if (currentSampleSize > -1 && excludedSampleSize > 0) {
            var filterInfoText = self.getFilterInfoString();
            infoText += "<div class=\"curveTooltipSampleSize\"><h4>" + excludedSampleSize.toFixed(2) + "% of sample data has been excluded</h4></div>\n";
            infoText += filterInfoText;
        }
        return infoText;
    });

    self.currentSampleSize = ko.pureComputed(function () {
        var sampleSize = 100;

        var yAxisVariable = self.yAxisVariable();

        if (yAxisVariable) {
            if (yAxisVariable.data.length === 0 && yAxisVariable.excludedData.length === 0) {
                return -1;
            } else {
                var includedDataLength = yAxisVariable.data.length;
                var excludedDataLength = yAxisVariable.excludedData.length;
                var dataLength = includedDataLength + excludedDataLength;

                sampleSize = (includedDataLength / dataLength) * 100;
                return sampleSize;
            }
        }

        return -1;
    });

    self.isTimePeriodExcluded = ko.pureComputed(function () {
        var excluded = false;
        var variableDataList = self.variableDataList();
        var currentSampleSize = self.currentSampleSize();

        if (currentSampleSize <= 0 || variableDataList.length === 0) {
            excluded = true;
        }

        return excluded;
    });

    self.formatedTimeRangeString = ko.pureComputed(function () {
        var startDate = dateHelper.getFormatedDateTimeString(self.startDate);
        var endDate = dateHelper.getFormatedDateTimeString(self.endDate);
        var text = startDate + "<br/>" + endDate;
        return text;
    });

    self.formatedStartDate = ko.pureComputed(function () {
        var startDate = dateHelper.getFormatedDateTimeString(self.startDate);
        return startDate;
    });

    self.formatedEndDate = ko.pureComputed(function () {
        var endDate = dateHelper.getFormatedDateTimeString(self.endDate);
        return endDate;
    });

    self.xAxisVariable = ko.pureComputed(function() {
      var variableId = self.isKognifai ? String(self.selectedCurve.xAxisLogVariableId) : self.selectedCurve.xAxisLogVariableId;
      var variable = self.findVariableById(self.variableDataList(), variableId);

      if (variable) {
        variable.id = variableId;
      }

      return variable;
    });

    self.yAxisVariable = ko.pureComputed(function () {
      var variableId = self.isKognifai ? String(self.selectedCurve.yAxisLogVariableId) : self.selectedCurve.yAxisLogVariableId;
      var variable = self.findVariableById(self.variableDataList(), variableId);

      if (variable) {
        variable.id = variableId;
      }

      return variable;
    });

    self.displayExcludedDataInfo = ko.pureComputed(function() {
        var currentSampleSize = self.currentSampleSize();

        if (currentSampleSize === -1 || currentSampleSize === 100) {
            return false;
        } else {
            return true;
        }
    });

    self.cpModule = ko.pureComputed(function () {
        return new CpModule(self);
    });

    self.isXAxisTimeVariable = ko.pureComputed(function () {
        return !self.xAxisVariable() && self.yAxisVariable();
    });
}

TimePeriod.prototype.getTimeDifferenceInHours = function (currentTime, previousTime, simpleDifference) {
    var timeDifferenceInHours = (currentTime - previousTime) / (this.hourInMilliseconds);
    var timeMultiplierInHours = timeConverter.secondsToHours(this.timeMultiplierInSeconds());

    if (simpleDifference) {
        return timeDifferenceInHours;
    }

    if (timeDifferenceInHours > timeMultiplierInHours) {
        timeDifferenceInHours = timeMultiplierInHours;
    }

    return timeDifferenceInHours;

};

TimePeriod.prototype.getReferenceValue = function(value) {
    var curveFit = this.selectedCurve.curveFit.toLowerCase();
    var regressionOrder = this.selectedCurve.regressionOrder;
    var dataPoints = _.clone(this.referenceCurveDataPoints);

    var referenceValue = curvePredictionHelper.predictRegressionValue(Number(value), dataPoints, curveFit, regressionOrder);

    return referenceValue;
};

TimePeriod.prototype.getExcludedDataFromFilterInfo = function(excludedFilterInfoData, variable) {
    var excludedData = variable.excludedData;
    var startDate = this.startDate;
    var endDate = this.endDate;
    var self = this;
    var list = [];
    for (var i = 0; i < excludedFilterInfoData.length; i++) {
        var value = excludedFilterInfoData[i].time;
        if (value >= startDate || value < endDate) {
            if (self.containsTime(excludedData, value)) {
                list.push(value);
            }
        }
    }

    return list;
};

TimePeriod.prototype.getFilterInfoString = function() {
    var self = this;
    var string = "";
    var filterInfoList = this.filterInfoList();
    var variableDataList = this.variableDataList();

    for (var i = 0; i < filterInfoList.length; i++) {
        for (var j = 0; j < filterInfoList[i].length; j++) {
            var filterInfo = filterInfoList[i][j];
            var variable = variableDataList[0];
            var excludedTimes = self.getExcludedDataFromFilterInfo(filterInfo.dataList.excluded, variable);

            var filterTimeInHours = (self.timeMultiplierInSeconds() * excludedTimes.length) / 3600;

            if (filterTimeInHours > 0) {
                string += "<div><span>" + filterInfo.filter.filterDescription + ": </span>" + "<span class=\"curveTooltipSampleTime\">" + filterTimeInHours.toFixed(2) + " hours</span></div>\n";
            }
        }
    }

    return string;
};

TimePeriod.prototype.getDeviationFromReferenceYAxisString = function () {
    var deviation = this.getDeviationFromReferenceYAxis();
    return deviation ? deviation.toFixed(2) : "No data";
};

TimePeriod.prototype.getDeviationFromReferenceYAxis = function () {
    if (this.currentSampleSize() > 0) {
        var yAxisVariable = this.yAxisVariable();
        var referenceYAxisValue = this.getReferenceYAxisValue();
        if (yAxisVariable && referenceYAxisValue) {
            return yAxisVariable.average - referenceYAxisValue;
        }
    }
};

TimePeriod.prototype.getReferenceYAxisValue = function() {
    var xAxisVariable = this.xAxisVariable();
    var yAxisVariable = this.yAxisVariable();

    if (this.isXAxisTimeVariable()) {
        var dataPoints = _.clone(this.referenceCurveDataPoints);
        return dataPoints[0][1];
    }
    else if (xAxisVariable.data && xAxisVariable.data.length > 0) {
        var curveFit = this.selectedCurve.curveFit.toLowerCase();
        var regressionOrder = this.selectedCurve.regressionOrder;
        var dataPoints = _.clone(this.referenceCurveDataPoints);

        var value = curvePredictionHelper.predictRegressionValue(xAxisVariable.average, dataPoints, curveFit, regressionOrder);

        return value ? value : "NaN";
    }
};

TimePeriod.prototype.getAverageValueString = function (variable) {
    var average = undefined;
    if (variable) {
        average = this.getAverageValueOfVariable(variable.id);
    }

    if (_.isUndefined(average) || _.isNaN(average)) {
        return "No data";
    } else {
        return average.toFixed(2);
    }
};

TimePeriod.prototype.getAverageValueOfVariable = function (variableId) {
    var variable = this.findVariableById(this.variableDataList(), variableId);

    if (variable) {
        return variable.average;
    }
};

TimePeriod.prototype.addVariables = function(variables, filterInfoList) {
    var self = this;
    _.each(variables, function (variable) {
        self.variableDataList.push({ id: variable.id, data: [], excludedData: [], name: variable.name, unit: variable.unit });
    });
    self.filterInfoList(filterInfoList);
};

TimePeriod.prototype.addVariableData = function (value, variableId, excluded) {
    var self = this;
    var variable = self.findVariableById(self.variableDataList(), variableId);

    if (variable) {
        if (excluded) {
            variable.excludedData.push(value);
        } else {
            variable.data.push(value);
        }
    }
};

TimePeriod.prototype.calculateAverageData = function () {
    var self = this;
    var variableDataList = this.variableDataList();
    _.each(variableDataList, function (variable) {
        var averageData = variableCalculations.getWeightedAverageOfVariableData(self, variable.data);

        variable.average = averageData.weightedAverage;
        self.includedTimeInHours(averageData.includedTimeInHours);
    });
};

TimePeriod.prototype.findVariableById = function (array, id) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].id === id) {
        return array[i];
    }
  }
};

TimePeriod.prototype.containsTime = function (array, time) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].time === time) {
        return true;
    }
  }
};

TimePeriod.prototype.getTableData = function() {
    var self = this;
    var cpModule = self.cpModule();
    var selectedCurve = self.selectedCurve;
    var curveMode = self.selectedCurve.curveMode;
    var tableData = {
        curveMode: curveMode,
        startDate: self.formatedStartDate(),
        endDate: self.formatedEndDate(),
        includedTimeInHours: self.includedTimeInHours(),
        excludedTimeInHours: self.excludedTimeInHours(),
    };

    if (curveMode === "TimeCharter") {
        tableData.actualFuelConsumption = cpModule.actualFuelConsumption();
        tableData.referenceFuelConsumption = cpModule.referenceFuelConsumption();
        tableData.differenceBetweenConsumptions = cpModule.differenceBetweenConsumptions();
        tableData.distanceTravelled = cpModule.distanceTravelled();
        tableData.deviationFromReferenceYAxis = self.getDeviationFromReferenceYAxis();
    } else if (curveMode === "SpotMarket") {
        var extrapolatedConsumption = self.extrapolatedConsumption();

        if (!_.isNaN(parseFloat(extrapolatedConsumption))) {
            tableData.extrapolatedConsumption = self.extrapolatedConsumption();
        }
    }

    tableData.excludedDataInfoText = $(self.excludedDataInfoText()).text();

    var xAxisVariable = self.xAxisVariable();
    if (xAxisVariable) {
        tableData.xAxisData = {
            variableName: xAxisVariable.name,
            averageValue: xAxisVariable.average,
        };
    }

    var yAxisVariable = self.yAxisVariable();
    tableData.yAxisData = {
        variableName: yAxisVariable.name,
        averageValue: yAxisVariable.average,
    };

    var additionalVariables = _.filter(self.variableDataList(), function(variable) {
        return variable.id !== yAxisVariable.id && (!xAxisVariable || variable.id !== xAxisVariable.id);
    });
    tableData.additionalData = _.map(additionalVariables, function(variable) {
        return {
            variableName: variable.name,
            averageValue: variable.average,
        };
    });

    return tableData;
};

export default TimePeriod;
