import ko from "knockout";
import _ from "underscore";
import Filter from "ViewModels/curves/filter";
import events from "App/events";

var ViewModel = function (availableVariablesMap, curveMode, yAxisLogVariableId, filterGroup, timeRangeFilter) {
    var self = this;
    self.availableVariablesMap = availableVariablesMap;
    self.filterGroup = filterGroup;
    self.timeRangeFilter = timeRangeFilter;
    self.yAxisLogVariableId = yAxisLogVariableId;
    self.curveMode = curveMode;

    var filterViewModels = [];
    if (self.filterGroup) {
        var filterViewModels = _.map(self.filterGroup,
            function (filter) {
                self.timeRangeFilter = filter.isTimeRangeFilter;
                return new Filter(self.availableVariablesMap, curveMode, self.yAxisLogVariableId, filter, self.timeRangeFilter);
            });
    } else {
        filterViewModels.push(new Filter(this.availableVariablesMap, curveMode, self.yAxisLogVariableId, undefined, self.timeRangeFilter));
    }

    self.filterViewModels = ko.observableArray(filterViewModels);

    self.removeFilter = function(filterViewModel) {
        this.filterViewModels.remove(filterViewModel);
        if (this.filterViewModels().length === 0) {
            events.filterGroupEmpty.dispatch(self);
        }
    }.bind(self);

    self.filters = ko.pureComputed(function() {
        var filterViewModels = self.filterViewModels();

        var filters = _.map(filterViewModels,
            function(filterViewModel) {
                return filterViewModel.filter();
            });

        return filters;
    });

    self.isFiltersValid = ko.pureComputed(function() {
        var filterViewModels = self.filterViewModels();
        return _.every(filterViewModels, function(filterViewModel) { return filterViewModel.isFilterValid(); });
    });
};

ViewModel.prototype.addFilter = function() {
    this.filterViewModels.push(new Filter(this.availableVariablesMap, this.curveMode, this.yAxisLogVariableId));
};

export default ViewModel;