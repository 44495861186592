import ko from "knockout";
import dateHelper from "Utilities/date-helper";
import moment from "moment";
import _ from "underscore";

var ViewModel = function (availableVariablesMap, curveMode, yAxisLogVariableId, filter, timeRangeFilter) {
    var self = this;
    var defaultToDate = moment.utc();
    var defaultFromDate = defaultToDate.clone().subtract(1, "days");

    self.operators = [">", "<", "="];
    self.availableVariablesMap = availableVariablesMap;
    self.filter = filter;
    self.timeRangeFilter = timeRangeFilter;
    self.yAxisLogVariableId = yAxisLogVariableId;
    self.curveMode = curveMode;

    self.variableId = ko.observable(filter && !filter.isTimeRangeFilter ? filter.variableId : null);
    self.operator = ko.observable(filter ? filter.operator : null);
    self.value = ko.observable(filter ? filter.value : 0);
    self.isContinuous = ko.observable(filter ? filter.isContinuous : false);
    self.continuousForInHours = ko.observable(filter ? filter.continuousForInHours : 0);

    self.timeRangeFromDateTime = ko.observable(filter ? filter.timeRangeFromDateTime : dateHelper.getSubmitDateString(defaultFromDate, "12:00"));
    self.timeRangeToDateTime = ko.observable(filter ? filter.timeRangeToDateTime : dateHelper.getSubmitDateString(defaultToDate, "12:00"));
    var fromDate = moment.utc(self.timeRangeFromDateTime(), "YYYY-MM-DDTHH:mm");
    var toDate = moment.utc(self.timeRangeToDateTime(), "YYYY-MM-DDTHH:mm");

    self.timeRangeFromDate = ko.observable(filter ? fromDate.format(dateHelper.getDateFormat()) : dateHelper.getFormatedDateString(defaultFromDate));
    self.timeRangeToDate = ko.observable(filter ? toDate.format(dateHelper.getDateFormat()) : dateHelper.getFormatedDateString(defaultToDate));
    self.timeRangeFromTime = ko.observable(filter ? fromDate.format(dateHelper.getTimeFormat()) : "12:00");
    self.timeRangeToTime = ko.observable(filter ? toDate.format(dateHelper.getTimeFormat()) : "12:00");
    self.timeRangeComment = ko.observable(filter ? filter.timeRangeComment : "");

    self.isVariableSelected = ko.pureComputed(function () {
        if (self.variableId()) {
            return true;
        }
        return false;
    });

    self.isTimeRangeFromTimeValid = ko.pureComputed(function () {
        var fromTime = self.timeRangeFromTime();
        return dateHelper.isValidTime(fromTime);
    });

    self.isTimeRangeToTimeValid = ko.pureComputed(function () {
        var toTime = self.timeRangeToTime();
        return dateHelper.isValidTime(toTime);
    });

    self.continuousForInMilliseconds = ko.pureComputed(function () {
        var hourInMilliseconds = 3600000;

        return self.continuousForInHours() * hourInMilliseconds;
    });

    self.filter = ko.pureComputed(function() {
        return self.getFilter();
    });

    self.unitName = ko.pureComputed(function() {
        var availableVariablesMap = self.availableVariablesMap();
        var variableId = self.variableId();
        var curveMode = self.curveMode().value;
        var yAxisLogVariableId = self.yAxisLogVariableId();

        if ((curveMode === "TimeCharter" || curveMode === "SpotMarket") && variableId === yAxisLogVariableId) {
            return "ton/day";
        } else {
            return availableVariablesMap.hasOwnProperty(variableId)
            ? availableVariablesMap[variableId].unit.caption
            : "";
        }
    });

    self.variableName = ko.pureComputed(function () {
        var availableVariablesMap = self.availableVariablesMap();
        var variableId = self.variableId();
        return availableVariablesMap.hasOwnProperty(variableId)
            ? availableVariablesMap[variableId].name
            : "";
    });

    self.variable = ko.pureComputed(function () {
        var availableVariablesMap = self.availableVariablesMap();
        var variableId = self.variableId();
        return availableVariablesMap.hasOwnProperty(variableId)
            ? availableVariablesMap[variableId]
            : null;
    });

    self.filterDescription = ko.pureComputed(function () {
        if (self.timeRangeFilter) {
            return "Time range filter";
        }
        var text = self.variableName() + " " + self.operator() + " "
            + self.value() + " " + self.unitName();

        if (self.isContinuous()) {
            text += " for " + self.continuousForInHours() + " hours";
        }
        return text;
    });

    self.isValueValid = ko.pureComputed(function() {
        var value = self.value();
        return _.isFinite(value);
    });

    self.isContinuousForInHoursValid = ko.pureComputed(function() {
        var continuousForInHours = self.continuousForInHours();
        return parseFloat(continuousForInHours) >= 0;
    });

    self.isFilterValid = ko.pureComputed(function() {
        var isValueValid = self.isValueValid();
        var isContinuousForInHoursValid = self.isContinuousForInHoursValid();
        var isTimeRangeFromTimeValid = self.isTimeRangeFromTimeValid();
        var isTimeRangeToTimeValid = self.isTimeRangeToTimeValid();
        var isVariableSelected = self.isVariableSelected();

        if (self.timeRangeFilter) {
            return isTimeRangeFromTimeValid && isTimeRangeToTimeValid;
        }
        return isValueValid && isContinuousForInHoursValid && isVariableSelected;
    });
};

ViewModel.prototype.getFilter = function() {
    var fromDateTime = dateHelper.getDateTime(this.timeRangeFromDate(), this.timeRangeFromTime());
    var toDateTime = dateHelper.getDateTime(this.timeRangeToDate(), this.timeRangeToTime());
    var fromInMilliseconds = dateHelper.getDateInMilliseconds(fromDateTime);
    var toInMilliseconds = dateHelper.getDateInMilliseconds(toDateTime);
    var formatedStartDate = dateHelper.getFormatedDateTimeString(fromDateTime);
    var formatedEndDate = dateHelper.getFormatedDateTimeString(toDateTime);

    var filter = {
        variableId: this.variableId(),
        operator: this.operator(),
        value: parseFloat(this.value()),
        isContinuous: this.isContinuous(),
        continuousForInHours: parseFloat(this.continuousForInHours()),
        continuousForInMilliseconds: parseFloat(this.continuousForInMilliseconds()),
        filterDescription: this.filterDescription(),
        timeRangeFromDateTime: fromDateTime,
        timeRangeToDateTime: toDateTime,
        timeRangeFromDateTimeInMillisecs: fromInMilliseconds,
        timeRangeToDateTimeInMillisecs: toInMilliseconds,
        timeRangeComment: this.timeRangeComment(),
        isTimeRangeFilter: this.timeRangeFilter,
        formatedStartDate: formatedStartDate,
        formatedEndDate: formatedEndDate,
    };

    return filter;
};

export default ViewModel;