import template from "./curve-data-table.html";
import kognifaiTemplate from "./curve-data-table-kognifai.html";
import ko from "knockout";
import _ from "underscore";
import CurvePoint from "ViewModels/curves/curvePoint";
import events from "App/events";
import Store from "@/store";

function ViewModel(params) {
    var self = this;

    self.variables = params.variables;
    self.timePeriods = params.timePeriods;
    self.title = params.title;
    self.selectedCurve = params.selectedCurve;
    self.curveMode = self.selectedCurve().curve().curveMode;

    var curvePointViewModels = _.map(self.selectedCurve().curve().points, function (point) {
        return new CurvePoint(point);
    });
    self.curvePointViewModels = ko.observableArray(curvePointViewModels);

    self.consumptionHeaderText = ko.pureComputed(function() {
        var curveMode = self.curveMode;
        var yAxisVariable = self.yAxisVariable();
        var yAxisUnitCaption = self.yAxisUnitCaption();

        if (curveMode === "TimeCharter") {
            return yAxisVariable.name + " Deviation (" + yAxisUnitCaption + ")";
        } else if (curveMode === "SpotMarket") {
            return "Extrapolated " + yAxisVariable.name + " (" + yAxisUnitCaption + ")";
        }

        return "";
    });

    self.referenceCurveDataPoints = ko.pureComputed(function () {
        var curvePointViewModels = self.curvePointViewModels();
        var curveDataPoints = _.map(curvePointViewModels,
            function (curvePointViewModel) {
                return curvePointViewModel.dataPoint();
            });

        curveDataPoints.sort(function (a, b) { return a[0] - b[0]; });

        return curveDataPoints;
    });

    self.xAxisVariable = ko.pureComputed(function () {
        var xAxisVariableId = self.selectedCurve().curve().xAxisLogVariableId;
        var variable = _.find(self.variables(), function(variable) {
            return variable.id === xAxisVariableId;
        });

        return variable;
    });

    self.yAxisVariable = ko.pureComputed(function () {
        var yAxisVariableId = self.selectedCurve().curve().yAxisLogVariableId;
        var variable = _.find(self.variables(), function (variable) {
            return variable.id === yAxisVariableId;
        });

        return _.isUndefined(variable) ? "" : variable;
    });

    self.sumOfVariables = ko.pureComputed(function() {
        var sumList = {};
    });

    self.sumOfIncludedTimes = ko.pureComputed(function() {
        var sum = 0;
        _.each(self.timePeriods(), function(timePeriod) {
            sum += timePeriod.includedTimeInHours();
        });

        return sum;
    });

    self.sumOfExcludedTimes = ko.pureComputed(function () {
        var sum = 0;
        _.each(self.timePeriods(), function (timePeriod) {
            sum += timePeriod.excludedTimeInHours();
        });

        return sum;
    });

    self.yAxisUnitCaption = ko.pureComputed(function () {
        var curveMode = self.curveMode;

        if (curveMode === "TimeCharter" || curveMode === "SpotMarket") {
            return "ton/day";
        }

        var yAxisVariable = self.yAxisVariable();

        return yAxisVariable ? yAxisVariable.unit.caption : "";
    });
}

ViewModel.prototype.isVariableDifferentFromXAndY = function(variable) {
    var xAxisVariable = this.xAxisVariable();
    var yAxisVariable = this.yAxisVariable();

    //Special case for time on x-axis
    if (!xAxisVariable && yAxisVariable) {
        if (variable.id !== yAxisVariable.id) {
            return true;
        }
    }
    if (variable && xAxisVariable && yAxisVariable) {
        return variable.id !== xAxisVariable.id && variable.id !== yAxisVariable.id;
    }

    return false;
};

ViewModel.prototype.showExcludedDataTooltip = function (vm, e) {
    if (vm.currentSampleSize() < 100) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: vm.excludedDataInfoText, customCssClass: "curveTableTooltip" }, element);
    }
};

ViewModel.prototype.closeToopltip = function () {
    events.closeTooltip.dispatch();
};

var templateToExport = (Store.getters["User/isKognifai"]) ? kognifaiTemplate : template;

export default {viewModel: ViewModel, template: templateToExport};
